const QaServices=[
      {
        "image":require("./Images/Qa Services/Automated Testing.png"),
        "name": "Automated Testing",
        "description": "With our expertise in test automation, we accelerate testing cycles, enhance test coverage, and deliver faster, error-free releases, optimizing your development process. Our proficient testers professionally handle various QA tasks and implement efficient testing solutions using modern tools and best QA practices."
      },
      {
        "image":require("./Images/Qa Services/Manual Testing.jpg"),
        "name": "Manual Testing",
        "description": "Our software quality engineers are dedicated to collaborating with clients to surmount these obstacles, ensuring thorough and effective end-to-end testing. Our test engineers with mature analytical knowledge, skills and broad testing experience the most relevant to your project."
      },
      {
        "image":require("./Images/Qa Services/Performance Testing.png"),
        "name": "Performance Testing",
        "description": "The immensely experienced and efficient team at Base Tech delivers end to end software performance testing solutions for effective working of the website and managing its quality. We make sure that your app is reliable and compatible to respond to peak load days and does not crash when subjected to unexpected additional load like seasonal sale or a product release."
      },
      {
        "image":require("./Images/Qa Services/API testing.jpg"),
        "name": "API Testing",
        "description": "Thorough API testing services are provided to validate the functionality, performance, security, and reliability of your application programming interfaces. We ensure that your API functions as intended by thoroughly testing its individual components and functionalities. We validate inputs, outputs, and expected behaviors to detect any issues early in the development process."
      },
      {
        "image":require("./Images/Qa Services/Security Testing.jpg"),
        "name": "Security Testing",
        "description": "Specific features of confidentiality, integrity, authentication, availability, authorization, and non-repudiation are often included as part of security requirements. To ensure that everything is in order, our testers perform security testing, exposing the vulnerabilities in the software's security measures, that are responsible for safeguarding data and maintaining software functionalities."
      },
      {
        "image":require("./Images/Qa Services/User Acceptance.png"),
        "name": "User Acceptance Testing (UAT)",
        "description": "Our UAT services encompass every facet of the testing process, from test planning and scenario development to execution and defect management. We work closely with your team to identify key user personas, define realistic usage scenarios, and establish clear acceptance criteria. By simulating real-world environments and user interactions, we uncover potential issues early on, allowing for timely resolutions and a smoother deployment process."
      },
      {
        "image":require("./Images/Qa Services/Cross Browser.png"),
        "name": "Cross Browser Testing",
        "description": "Cross browser testing must be an integral part of any web application testing. At present there are many various browsers. The users have different preferences; even one user may work with several browsers in turns or simultaneously."
      },
      {
        "image":require("./Images/Qa Services/Accessibility.png"),
        "name": "Accessibility Testing",
        "description": "We conduct comprehensive accessibility testing to ensure that your software is accessible to all individuals, including those with disabilities, adhering to international accessibility standards."
      }
    ]

export default QaServices