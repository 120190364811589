import React from 'react';
import { useDarkMode } from './DarkModeContext';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const DarkModeToggle = () => {
    const { isDarkMode, toggleDarkMode } = useDarkMode();

    return (
        <DarkModeSwitch
            style={{ marginLeft: '2rem' }}
            checked={isDarkMode}
            onChange={toggleDarkMode}
            size={30}
        />
    );
};

export default DarkModeToggle;
