import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";
function ContactPage() {
  return (
    <div>
      <Header /><br/><br/><br/><br/>
      <Contact/>
      <Footer />
    </div>
  );
}

export default ContactPage;
