import styled from "styled-components";
import { media } from "../../Styles/utils";

export const InfoContainer=styled.div`
display:flex;
flex-direction:row;
align-items:center;
gap:0;
margin-top:-19px;
margin-bottom:-19px;
width:100%;
justify-content:center;
padding-top:20px;
padding-bottom:20px;
${media("medium")}{
    flex-direction:column;
}
`;
export const InfoDiv=styled.div`
background-color:${(props)=> props.back};
height:150px;
padding:4.5%;
width:100%;
align-items:center;
display:flex;
flex-direction:column;
gap:30px;
text-align:center;
justify-content:center;
${media("medium")}{
    width:90%;
}
`;

export const InfoImage=styled.img`
width:100px;
`;

export const InfoNumber=styled.div`
color:${(props)=>props.textcolor};
font-size:40px;
font-weight:bold;
border-bottom:1px solid black;
`;

export const InfoSub=styled.div`
color:${(props)=>props.textcolor};
font-size:20px;
font-weight:bold;
`;

