import React from 'react';
import { AboutCardContainer, AboutContainer, AboutHeading, AboutValuesCard, AboutValuesHeading, AboutValuesImage, AboutValuesSub } from './AboutCards.styles';
import { useDarkMode } from '../../DarkModeContext';

function AboutCards({ Values, Heading }) {
    const { isDarkMode } = useDarkMode();

    return (
        <AboutContainer theme={isDarkMode}>
            <AboutHeading theme={isDarkMode}>{Heading}</AboutHeading>
            <AboutCardContainer>
                {Values.map((value, index) => (
                    <AboutValuesCard key={index}>
                        <AboutValuesImage src={value.Icon} />
                        <AboutValuesHeading>{value["Values Heading"]}</AboutValuesHeading>
                        <AboutValuesSub>{value["Values Description"]}</AboutValuesSub>
                    </AboutValuesCard>
                ))}
            </AboutCardContainer>
        </AboutContainer>
    );
}

export default AboutCards;
