import styled from "styled-components";
import { media } from "../../Styles/utils";


export const VerifyContainer=styled.div`
width:100%;
height:100%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:Center;
gap:10px;
${media("medium")}{
    height:100%;
}
`;

export const TickImage=styled.div`
width:120px;
height:120px;
${media("medium")}{
    widht:60px;
    height:60px;
}
`;

export const VerifiedText=styled.span`
font-family:"Outfit";
font-size:25px;
font-weight:400;
text-align:center;
padding:10px;
`;


export const CertifiedText=styled.span`
font-family:"Outfit";
font-size:35px;
font-weight:600;
text-align:center;
`;
