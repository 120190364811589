
export const Colors = {
    TestimonialBackground: "#d9d9d9",
    Text: "black",
    BlogsSubText: "#2384b5",
    AboutSectionBackground: "#f0f0f0",
    AboutCardBackground: "#d9d9d9",
    ContactContainerBackground: "linear-gradient(90.21deg, #1d749c 30.91%, #08a9e4 88.58%)",
    ContactContainerColor: "#ffffff", // Color for text in ContactContainer
    ContactFormInputBackground: "rgba(255, 255, 255, 0.1)", // Background for input fields
    ContactFormInputBorder: "rgba(255, 255, 255, 0.5)", // Border color for input fields
    ContactFormInputColor: "#ffffff", // Color for text in input fields
    ContactFormInputPlaceholder: "rgba(255, 255, 255, 0.8)", // Color for input placeholders
    ContactFormButtonColor: "#000", // Color for button text
    ContactFormButtonBackground: "#fff", // Background color for button
    ContactFormButtonHoverColor: "#fff", // Color for button text on hover
    ContactFormButtonHoverBackground: "#121212", // Background color for button on hover
    SuccessMessage:"green",
    DangerMessage:"red",
    ContactFormTextAreaFocus:"rgba(255, 255, 255, 1)",
    FooterContainerBackground:"#fff5ee",
    FooterBorder:"#727272",
    NavBackground:"#fff",
    NavLinkColor:"#000",
    NavExtendedColor:"#fff",
    DropdownHover:" #f5f5f5",
    ContactButtonColor:"black",
    ButtonText:"white",
    ButtonHover:"#1d748e",
    TextWrapperColor:"#FFF",
    SpinWords:"#0ba2ce",
    ServiceHeadingColor:"white",
    ServiceCardBackground:"#D9D9D9",
    AICaseColor:"#d9d9d9",
    HeroMainButtonHover:"black",
    HeroMainBackgroundHover:"white",
    HeroMainBackgroud:"black",
    HeroMainText:"white",
    HeroTextWrapperColor:"white",
    BelowTextCOlor:"black",
    BelowBlogTextcolor:"#1a8cb6"
};

  
export const Dark = {
    TestimonialBackground: "gray",
    Text: "black",
    BlogsContainerColor:"linear-gradient(to right, #004ff9, #000000)",
    BlogsSubText: "white",
    BlogHeading:"white",
    AboutSectionBackground: "#d9d9d9",
    AboutCardBackground: "#f0f0f0",
    ContactContainerBackground: "linear-gradient(90.21deg, #1d749c 50.91%, #08a9e4 88.58%)",
    ContactContainerColor: "#ffffff", // Color for text in ContactContainer
    ContactFormInputBackground: "rgba(255, 255, 255, 0.1)", // Background for input fields
    ContactFormInputBorder: "rgba(255, 255, 255, 0.5)", // Border color for input fields
    ContactFormInputColor: "#ffffff", // Color for text in input fields
    ContactFormInputPlaceholder: "rgba(255, 255, 255, 0.8)", // Color for input placeholders
    ContactFormButtonColor: "#000", // Color for button text
    ContactFormButtonBackground: "#fff", // Background color for button
    ContactFormButtonHoverColor: "#fff", // Color for button text on hover
    ContactFormButtonHoverBackground: "#121212", // Background color for button on hover
    SuccessMessage:"lightgreen",
    DangerMessage:"red",
    ContactFormTextAreaFocus:"rgba(255, 255, 255, 1)",
    FooterContainerBackground:"#D9D9D0",
    FooterBorder:"#727272",
    NavBackground:"#000",
    NavLinkColor:"#fff",
    NavExtendedColor:"#000",
    DropdownHover:"#4f4b4b",
    ContactButtonColor:"white",
    ButtonText:"black",
    Textp:"white",
    HoverButtonText:"white",
    HeroBack:"#858585",
    ButtonHover:"#1d749c",
    TextWrapperColor:"#0000",
    SpinWords:"#56cff5",
    ServiceHeadingColor:"white",
    ServiceCardBackground:"#D9D9D9",
    AICaseColor:"black",
    HeroMainButtonHover:"black",
    HeroMainBackgroundHover:"white",
    HeroMainBackgroud:"black",
    HeroMainText:"white",
    HeroTextWrapperColor:"white",
    BelowTextCOlor:"white",
    BelowBlogTextcolor:"#1a8cb6",
    BelowSliderColor:"#2384b5 linear-gradient(147deg, #2384b5 0%, #000000 74%)",
};