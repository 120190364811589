import styled from "styled-components";
import { media } from "../../Styles/utils";
import servicesback from "../../Assets/Images/Serviceback2.jpg";
import { Colors as colors } from "../../utils/Colors";

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background:linear-gradient(to top, #004ff9, #000000);
  background-size: cover;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
`;

export const ServicesHeading = styled.span`
  font-family: 'Outfit';
  font-size: 65px;
  font-weight: bold;
  color: ${colors.ServiceHeadingColor};
  padding-bottom: 30px;
  text-align: center;
  ${media("medium")} {
    font-size: 40px;
  }
  ${media("small")} {
    font-size: 30px;
  }
`;

export const ServiceDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  ${media("medium")} {
    flex-direction: column;
    gap:0px;
  }
  ${media("small")} {
    flex-direction: column;
    gap:0px;
  }
`;
export const ServiceCard=styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-items:center;
width:100%;
position:relative;
border-radius:25px;
${media("medium")} {
    flex-direction: column;
    height:100%;
  }
  ${media("small")} {
   width:100%;
   height:100%;
  }
  &::before {
    content: "";
    background: linear-gradient(90.21deg, #1d749c 50.91%, #08a9e4 88.58%);
    opacity: 0.85;
    position: absolute;
    border-radius:25px;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
  }
  &:hover::before {
    width:100%;
    height: 100%;
  }
  &:hover .proj-txtx {
    top: 50%;
    opacity: 1;
  }
`;

export const ServiceImage=styled.img`
width:650px;
height:100%;
border-radius:25px;
${media("medium")}{
  width:100%;
  height:100%;
}
`;

export const ServiceText = styled.div`
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  width:100%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  color:${colors.ServiceCardBackground};
  .prog-imgbx:hover & {
    top: 50%;
    opacity: 1;
  }
`;

export const ServiceHead=styled.div`
  font-family: 'Outfit';
  font-size: 45px;
  font-weight: bold;
  padding-top: 30px;
  width:100%;
  ${media("medium")} {
    font-size: 28px;
  }
  ${media("small")} {
    font-size: 18px;
  }
`;

export const ServiceTextSub=styled.div`
text-align: left;
  font-family: 'Outfit';
  font-size: 15px;
  font-weight: normal;
  padding: 30px;
  ${media("medium")} {
    font-size: 10px;
  }
  ${media("small")} {
    font-size: 8px;
  }
`;
