import React, { useState } from 'react';
import { ContactContainer, ContactFormButton, ContactFormInput, ContactImage, ContactInfo } from '../components/Contact/Contact.styles';
import contactback from "../Assets/Images/contact-img.svg";
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { BlogsHeading } from '../components/Blogs/blogs.styles';

function VerifyCertificate() {
    const [formDetails, setFormDetails] = useState({ certificateNumber: '' });
    const [isHovered, setIsHovered] = useState(false);

    const onFormUpdate = (field, value) => {
        // Allow only numbers and limit the length to 6
        if (/^\d{0,12}$/.test(value)) {
            setFormDetails({
                ...formDetails,
                [field]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = `/verifycertificate/${formDetails.certificateNumber}`;
    };

    const isButtonDisabled = formDetails.certificateNumber.length !== 12;

    return (
        <>
            <Header />
            <br /><br /><br /><br />
            <ContactContainer>
                <ContactImage src={contactback} />
                <ContactInfo>
                    <BlogsHeading style={{ color: "white", textAlign: "left" }}>
                        Verify Your Certificate
                    </BlogsHeading>
                    <form
                        onSubmit={handleSubmit}
                        style={{ width: "70%", justifyContent: "space-between" }}
                    >
                        <ContactFormInput
                            type="text"
                            value={formDetails.certificateNumber}
                            placeholder="Certificate Number"
                            onChange={(e) => onFormUpdate("certificateNumber", e.target.value)}
                            required
                        />
                        <ContactFormButton
                            type="submit"
                            disabled={isButtonDisabled}
                            style={{
                                marginTop: '20px',
                                cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                                opacity: isButtonDisabled ? 0.5 : 1
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <span style={{ color: isHovered && !isButtonDisabled ? "white" : "black" }}>Submit</span>
                        </ContactFormButton>
                    </form>
                </ContactInfo>
            </ContactContainer>
            <Footer />
        </>
    );
}

export default VerifyCertificate;
