import React from 'react'
import { InfoContainer, InfoDiv, InfoImage, InfoNumber, InfoSub } from './Info.styles';
import Arrow from "../../Assets/Images/arrow.svg";

function Info() {
  return (
    <InfoContainer>
      <InfoDiv back="#1d749c">
        <InfoImage src="https://cdn-icons-png.flaticon.com/512/4815/4815834.png" />
        <InfoSub>FAST ONBOARDING</InfoSub>
      </InfoDiv>
      <InfoDiv back="#2698bd"><InfoImage src="https://cdn-icons-png.flaticon.com/512/9975/9975755.png"/>
        <InfoSub>ACCOUNTABILITY</InfoSub>
      </InfoDiv>
      <InfoDiv back="#0ba2ce"><InfoImage src="https://cdn-icons-png.flaticon.com/512/1076/1076744.png" />
        <InfoSub>TRANSPARENCY</InfoSub>
      </InfoDiv>
      <InfoDiv back="#08a9e4"><InfoImage src="https://cdn-icons-png.flaticon.com/512/1371/1371682.png"/>
        <InfoSub> CONSISTENT DELIVERY</InfoSub>
      </InfoDiv>
    </InfoContainer>
  )
}

export default Info
