import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {
  HeroContainer,
  HeroTextP,
  HeroTextWrapper,
  HeroMainButton,
} from "./styles/Hero.styles";
import { HeroSub } from "../components/Hero/hero.styles";
import { Words } from "../components/RotatingText/RotatingText.styles";
import background from "../Assets/Images/webdev.jpg";
import Aboutdata from "../data/AboutWebDev.json";
import About from "../components/About/About";
import { AiCaseStudies } from "./styles/Ai.styles";
import CaseStudy from "../components/CaseStudies";
import WebDevCaseStudies from "../data/WebDevCaseStudies.json";
import Services from "../components/Services/Services";
import WebDevServices from "../data/WebDevservices";
import { useDarkMode } from "../DarkModeContext";
import { useSpring, animated } from 'react-spring';

function WebDev() {
  const { isDarkMode } = useDarkMode();

  // State variables for visibility of each component
  const [isVisibleHero, setIsVisibleHero] = useState(true);
  const [isVisibleAbout, setIsVisibleAbout] = useState(false);
  const [isVisibleCaseStudies, setIsVisibleCaseStudies] = useState(false);
  const [isVisibleServices, setIsVisibleServices] = useState(false);
  const [isVisibleFooter, setIsVisibleFooter] = useState(false);

  // Refs for each component
  const refHero = useRef();
  const refAbout = useRef();
  const refCaseStudies = useRef();
  const refServices = useRef();
  const refFooter = useRef();

  // Animations for each component
  const animationHero = useSpring({
    opacity: isVisibleHero ? 1 : 0,
    transform: isVisibleHero ? 'translateY(0)' : 'translateY(80px)',
  });

  const animationAbout = useSpring({
    opacity: isVisibleAbout ? 1 : 0,
    transform: isVisibleAbout ? 'translateY(0)' : 'translateY(80px)',
  });

  const animationCaseStudies = useSpring({
    opacity: isVisibleCaseStudies ? 1 : 0,
    transform: isVisibleCaseStudies ? 'translateY(0)' : 'translateY(80px)',
  });

  const animationServices = useSpring({
    opacity: isVisibleServices ? 1 : 0,
    transform: isVisibleServices ? 'translateY(0)' : 'translateY(80px)',
  });

  const animationFooter = useSpring({
    opacity: isVisibleFooter ? 1 : 0,
    transform: isVisibleFooter ? 'translateY(0)' : 'translateY(80px)',
  });

  useEffect(() => {
    // Check screen size and set isVisibleAutoPlay accordingly
    const handleResize = () => {
      const isMediumOrSmall = window.innerWidth <= 1024; // Define your medium or small size threshold
      setIsVisibleAbout(isMediumOrSmall);
    };

    // Call handleResize initially
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleScroll = () => {
    if (refAbout.current) {
      const topAbout = refAbout.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleAbout(topAbout < windowHeight * 0.8);
    }
    if (refCaseStudies.current) {
      const topCaseStudies = refCaseStudies.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleCaseStudies(topCaseStudies < windowHeight * 0.8);
    }
    if (refServices.current) {
      const topServices = refServices.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleServices(topServices < windowHeight * 0.8);
    }
    if (refFooter.current) {
      const topFooter = refFooter.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleFooter(topFooter < windowHeight * 0.8);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <Header />
      <animated.div style={animationHero} ref={refHero}>
        <HeroContainer
          background={background}
          style={{ backgroundPosition: "bottom" }}
          theme={isDarkMode}
        >
          <HeroTextWrapper theme={isDarkMode}>
            <HeroTextP theme={isDarkMode}>Custom Software Development Solutions</HeroTextP>
            <Words theme={isDarkMode}></Words>
            <HeroSub style={{ color: "white" }} theme={isDarkMode}>
              Drive your business growth and stand out online with BaSetech's comprehensive custom web development services, delivering a complete solution from concept to launch.
            </HeroSub>
            <HeroMainButton theme={isDarkMode} to="/ContactUs">Connect With Us</HeroMainButton>
          </HeroTextWrapper>
        </HeroContainer>
      </animated.div>
      <animated.div style={animationAbout} ref={refAbout}>
        <About {...Aboutdata} theme={isDarkMode} />
      </animated.div>
      <animated.div style={animationServices} ref={refServices}>
        <Services services={WebDevServices} theme={isDarkMode} />
      </animated.div>
      {/* <animated.div style={animationCaseStudies} ref={refCaseStudies}>
        <AiCaseStudies theme={isDarkMode}>
          <CaseStudy theme={isDarkMode} CaseStudies={WebDevCaseStudies.CaseStudies} blogsheading={"Our Recent Endeavours"} />
        </AiCaseStudies>
      </animated.div> */}
      <animated.div style={animationFooter} ref={refFooter}>
        <Footer />
      </animated.div>
    </div>
  );
}

export default WebDev;
