import styled, { keyframes } from "styled-components";
import { media } from "../../Styles/utils";
import { Colors as colors, Dark } from "../../utils/Colors";
export const TextWrapper = styled.div`
  box-sizing: content-box;
  background-color: ${(props)=> props.theme==true?Dark.TextWrapperColor:colors.TextWrapperColor};
  width:80%;
  padding-left:13%;
  padding-bottom:50px;
  text-align:left;
  overflow:hidden;
  background:transparent;
  border-radius: 8px;
  ${media('medium')}{
    height:80%;
    padding-left:10px;
  }
  ${media('small')}{
    height:100%;
    width:90%;
    padding-left:10px;
    align-items:center;
  }
`;
export const TextP = styled.p`
font-size:70px;
font-family:'Outfit';
position:relative;
z-index:5;
color:${(props)=>props.theme==true? Dark.Textp:""};
font-weight:bold;
${media('medium')}{
    font-size:45px;
}
${media('small')}{
    font-size:35px;
}
`;
const spinWordsAnimation = keyframes`
10%{
  transform:translateY(0%);
}
50%{
    transform:translateY(-100%);
}
90%{
    transform:translateY(-200%);
}
`;

export const Words = styled.div`
  overflow: hidden;
  height:120px;
  margin-top:-170px;
  margin-left:250px;
  position:relative;
z-index:5;
  ${media('medium')}{
    margin-top:-145px;
    margin-left:200px;
    height:80px;
  }
  ${media('small')}{
    margin-top:-87px;
    margin-left:125px;
    align-items:center;
    height:55px;
  }
`;

export const Span = styled.span`
  display: block;
  height: 100%;
  position:relative;
z-index:5;
  font-family:'Outfit';
  font-weight:bold;
  padding: 10px;
  font-size:70px;
  color:${(props)=> props.theme==true?Dark.SpinWords:colors.SpinWords};
  animation: ${spinWordsAnimation} 12s infinite;
  ${media('medium')}{
    font-size:45px;
  }
  ${media('small')}{
    font-size:35px;
  }
`;