// RotatingText.js
import React from "react";
import { Span, TextP, TextWrapper, Words } from "./RotatingText.styles";
import { HeroButton, HeroSub } from "../Hero/hero.styles";
function RotatingText({theme}) {
  return (
    <TextWrapper theme={theme}>
      <TextP theme={theme}>Elevate Your <br />Project</TextP>
      <Words theme={theme}>
        <Span theme={theme}>Swiftly</Span>
        <Span theme={theme}>Seamlessly</Span>
        <Span theme={theme}>Efficiently</Span>
      </Words>
      <HeroSub theme={theme}>
        Welcome to Basetech, your elite AI technology partner. Together, we'll build from the base and elevate your business to new heights. Experience the synergy of innovation and partnership with us.
      </HeroSub>
      <HeroButton to="/ContactUs" theme={theme}>Let's Talk</HeroButton>
    </TextWrapper>
  );
}

export default RotatingText;
