import React from 'react'
import Header from '../components/Header/Header'
import { CertifiedText, TickImage, VerifiedText, VerifyContainer } from './styles/VerifyCertificate.styles';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Footer from '../components/Footer/Footer';

function NotFound() {
    return (
        <>
            <Header />
            <br /><br /><br /><br /><br /><br />
            <VerifyContainer>
                <TickImage>
                    <DangerousIcon style={{ width: "100%", height: "100%", color: "red" }} />
                </TickImage>
                <CertifiedText>404 Not Found</CertifiedText>
                <verifiedText>The Page you are looking for doesn't exist</verifiedText>
            </VerifyContainer>
            <br /><br />
            <Footer />
        </>
    )
}

export default NotFound