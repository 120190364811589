import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Colors } from "../../utils/Colors";

export const AboutCardContainer = styled.div`
display: flex;
  flex-wrap:wrap;
  gap: 30px;
  align-items: center;
  justify-content:center;
  ${media("medium")} {
    flex-direction: column;
    gap:20px;
  }
  ${media("small")} {
    flex-direction: column;
    gap:20px;
  }`;

export const AboutContainer = styled.div`display: flex;
  flex-direction: column;
  background:white;
  background-size: cover;
  align-items: center;
  gap:10px;
  padding: 30px;`

export const AboutHeading = styled.span`
  font-family: 'Outfit';
  font-size: 65px;
  font-weight: bold;
  color: ${ Colors.Text};
  padding-bottom: 30px;
  text-align: center;
  ${media("medium")} {
    font-size: 40px;
  }
  ${media("small")} {
    font-size: 30px;
  }
`;

export const AboutValuesCard = styled.div`
    width: 400px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border:1px solid black;
    padding:20px;
    justify-content: center;
    transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow */
    &:hover {
        box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4); /* Add box-shadow on hover */
    }
    ${media("medium")} {
    height:100%;
    width:350px;
    padding:15px;
  }
  ${media("small")} {
    height:100%;
    width:300px;
    padding:15px;
  }`;

export const AboutValuesImage=styled.img`
width:90px;
height:90px;
padding-left:20px;
`;

export const AboutValuesHeading=styled.span`
font-family:"Outfit";
font-size:20px;
font-weight:bold;
padding:20px;
`;

export const AboutValuesSub=styled.span`
font-family:"Outfit";
font-size:15px;
padding-left:20px;
`;