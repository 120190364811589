import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { TestimonialCard, TestimonialContainer, TestimonialImage, TestimonialName, TestimonialText, Testimonialtextcontainer } from './Testimonial.styles';
import { BlogCard, BlogHeading, BlogImage, BlogInner, BlogSub, BlogText, BlogsContainer, BlogsHeading, BlogsSub } from '../Blogs/blogs.styles';
import testimonialsdata from "./Testimonials.json";
import testimonyimage1 from "./TestimonyImages/stock 1.png";
import testimonyimage2 from "./TestimonyImages/stock 2.png";
import Arrow from "../../Assets/Images/arrow.svg";
import ArrowFilled from "../../Assets/Images/ArrowBlack.svg";
import testimonyimage3 from "./TestimonyImages/stock 3.png";
import testimonyimage4 from "./TestimonyImages/stock 4.png";
import small1 from "./TestimonyImages/small stock1.png";
import small2 from "./TestimonyImages/small stock2.png";
import small3 from "./TestimonyImages/small stock3.png";
import small4 from "./TestimonyImages/small stock4.png";
import { useDarkMode } from '../../DarkModeContext';

const Testimonials = () => {
  const images = [testimonyimage1, testimonyimage2, testimonyimage3, testimonyimage4];
  const small=[small1,small2,small3,small4];
  const sliderRef = useRef(null); // Create a ref for the Slider component
  const [hoveredArrow, setHoveredArrow] = useState(null);
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      @media (min-width: 768px) {
        .slick-track {
          padding: 30px;
          height:650px
        }
        .TestimonialCont{
          flex-direction:row;
          justify-content:flex-start;
        }
      }
      @media (max-width: 767px) {
        .slick-track {
          padding: 0;
          height:100%;
        }
        .TestimonialCont{
          flex-direction:column;
          gap:5px;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const { isDarkMode } = useDarkMode();
  const renderArrows = () => {
    return (
      <div className="slider-arrow">
        <img
          src={hoveredArrow === "left" ? ArrowFilled : Arrow}
          alt="Left Arrow"
          style={{ width: '54px', height: '54px', cursor: 'pointer', marginRight: '10px', borderRadius: '50%',filter: isDarkMode ? "invert(1)" : "none" }}
          onClick={() => sliderRef.current.slickPrev()}
          className="arrow-img"
          onMouseEnter={() => setHoveredArrow("left")}
          onMouseLeave={() => setHoveredArrow(null)}
        />
        <img
          src={hoveredArrow === "right" ? ArrowFilled : Arrow}
          alt="Right Arrow"
          style={{ width: '54px', height: '54px', cursor: 'pointer', transform: "rotate(180deg)", borderRadius: '50%', filter: isDarkMode ? "invert(1)" : "none" }}
          onClick={() => sliderRef.current.slickNext()}
          className="arrow-img"
          onMouseEnter={() => setHoveredArrow("right")}
          onMouseLeave={() => setHoveredArrow(null)}
        />
      </div>
    );
  };
  const sliderWidth = window.innerWidth >= 768 ? "65%" : "100%";
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    centerMode: true, // Center mode to center the slide
    centerPadding: "0px", // No padding on the sides
    autoplaySpeed: 2000,
    cssEase: "linear",
    dots: true,
    slidesToShow: 1, // Show 3 slides by default
    arrows: false, // Hide arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false, // Hide arrows on smaller screens
          centerMode: true, // Enable center mode for smaller screens
          centerPadding: "0px", // No padding on the sides
        },
      },
    ],
  };

  return (
    <BlogsContainer theme={isDarkMode} className='TestimonialCont'>
      <BlogsHeading style={{ width: "80%" }} theme={isDarkMode}>What Our clients say....<br /><br />
        {window.innerWidth >= 768 && renderArrows()} {/* Render custom arrows */}</BlogsHeading>
      <Slider theme={isDarkMode} {...settings} style={{ width: sliderWidth, display: "flex" }} ref={sliderRef}>
        {testimonialsdata && Array.isArray(testimonialsdata) && testimonialsdata.map((testimonies, index) => (
          <BlogInner key={index} theme={isDarkMode}  >
            <BlogCard key={index} theme={isDarkMode} style={{ width: "90%", flexDirection: window.innerWidth >= 868 ? "row" : "column", height: window.innerWidth>=768? "580px":"500px" }}>
              <TestimonialImage theme={isDarkMode} style={{
                width: window.innerWidth >= 768 ? "45%" : "300px",
                height: window.innerWidth >= 768 ? "100%" : "200px",
                margin: "0 auto",
                display:"flex",
                alignItems:"center",

              }}>
                <img src={window.innerWidth<=768? small[index]: images[index]} alt={`Testimony ${index + 1}`} style={{
                  width: "100%",
                  height: window.innerWidth >= 768 ? "70%" : "100%",
                  objectFit:"cover"
                }} />
              </TestimonialImage>

              <BlogText theme={isDarkMode} style={{ width: "90%" }}>
              <BlogHeading>
                <FontAwesomeIcon icon={faQuoteLeft} /><br/> {testimonies.quote}
              </BlogHeading>
              <br></br>
                {/* <TestimonialText>{testimonies.testimony}</TestimonialText><br /><br /> */}
                <BlogSub>-{testimonies.name}</BlogSub>
              </BlogText>
            </BlogCard>
          </BlogInner>
        ))}
      </Slider><br /><br />
      {window.innerWidth < 768 && renderArrows()}
    </BlogsContainer>
  );
};

export default Testimonials;
