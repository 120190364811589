const WebDevServices= [
    {
      "name": "Frontend Development",
      "description": "Experience seamless digital interfaces with Basetech's Frontend Development services. Our expertly crafted web presentations not only look stunning but also engage and convert, providing users with an exceptional online experience.",
      "image": require("./Images/Web Services/Front End.jpg")
    },
    {
      "name": "Backend Development",
      "description": "Power your platforms with robust and reliable Backend Development from Basetech. Our secure, scalable, and speedy server-side solutions ensure your applications run smoothly, keeping your business ahead in the tech race.",
      "image": require("./Images/Web Services/BackEnd.jpg")
    },
    {
      "name": "Native Mobile App Development",
      "description": "Bring your mobile vision to life with Basetech's Native Mobile App Development. Tailored for specific platforms, our apps offer optimal performance, harnessing the full potential of smartphone technology to captivate your audience.",
      "image": require("./Images/Web Services/MobileApp.jpg")
    },
    {
      "name": "UI/UX Designs",
      "description": "Craft compelling digital experiences with Basetech's UI/UX Design services. Our user-centric designs are intuitive, accessible, and engaging, ensuring a seamless journey that resonates with your audience and amplifies user satisfaction.",
      "image": require("./Images/Web Services/UI-UX.jpg")
    },
    {
      "name": "E-Commerce Website Development",
      "description": "Accelerate your online sales with Basetech's E-Commerce Website Development. From eye-catching storefronts to smooth checkout processes, we build platforms that are secure, scalable, and designed to drive conversions and grow your business.",
      "image": require("./Images/Web Services/E-Commerce.jpg")
    },
    {
      "name": "Data Analytics Dashboards Development",
      "description": "Make informed decisions with Basetech's Data Analytics Dashboards Development. Our custom dashboards transform complex data into actionable insights, presenting them in an interactive and easy-to-understand format that empowers your business strategy.",
      "image": require("./Images/Web Services/Analytics.jpg")
    }
  ];

export default WebDevServices;
