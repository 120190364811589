import React, { useState, useRef, useEffect } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import background from "../Assets/Images/Serviceback2.jpg";
import {
  HeroContainer,
  HeroTextWrapper,
  HeroTextP,
} from "./styles/Hero.styles";
import { HeroSub } from "../components/Hero/hero.styles";
import { Words } from "../components/RotatingText/RotatingText.styles";
import Contact from "../components/Contact/Contact";
import AboutCards from "../components/About/AboutCards";
import values from "../data/Values.json";
import focus from "../data/Focus.json";
import { useSpring, animated } from 'react-spring';

function Aboutus() {
  const [isVisibleHero, setIsVisibleHero] = useState(true);
  const [isVisibleValues, setIsVisibleValues] = useState(true);
  const [isVisibleFocus, setIsVisibleFocus] = useState(false);
  const [isVisibleContact, setIsVisibleContact] = useState(false);
  const [isVisibleFooter, setIsVisibleFooter] = useState(false);

  const refValues = useRef();
  const refFocus = useRef();
  const refContact = useRef();
  const refFooter = useRef();

  const animationHero = useSpring({
    opacity: isVisibleHero ? 1 : 0,
    transform: isVisibleHero ? 'translateY(0)' : 'translateY(50px)',
  });

  const animationValues = useSpring({
    opacity: isVisibleValues ? 1 : 0,
    transform: isVisibleValues ? 'translateY(0)' : 'translateY(50px)',
  });

  const animationFocus = useSpring({
    opacity: isVisibleFocus ? 1 : 0,
    transform: isVisibleFocus ? 'translateY(0)' : 'translateY(50px)',
  });

  const animationContact = useSpring({
    opacity: isVisibleContact ? 1 : 0,
    transform: isVisibleContact ? 'translateY(0)' : 'translateY(50px)',
  });

  const animationFooter = useSpring({
    opacity: isVisibleFooter ? 1 : 0,
    transform: isVisibleFooter ? 'translateY(0)' : 'translateY(50px)',
  });

  const handleScroll = () => {
    if (refValues.current) {
      const topValues = refValues.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleValues(topValues < windowHeight * 0.8);
    }

    if (refFocus.current) {
      const topFocus = refFocus.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleFocus(topFocus < windowHeight * 0.8);
    }

    if (refContact.current) {
      const topContact = refContact.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleContact(topContact < windowHeight * 0.8);
    }

    if (refFooter.current) {
      const topFooter = refFooter.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleFooter(topFooter < windowHeight * 0.8);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <Header />
      <animated.div style={animationHero}>
        <HeroContainer background={background}>
          <HeroTextWrapper style={{ width: "100%", padding: window.innerWidth < 768 ? "15px" : "" }}>
            <HeroTextP>About BASETECH</HeroTextP>
            <Words></Words>
            <HeroSub style={{ color: "white", width: "80%" }}>
            Basetech provide innovative, customer-centric, result-oriented, cost-competitive, innovative & functional IT solutions & Services for our client’s business to stand out in the industry. 
Our dedication to excellence is evident in our expansive range of services—from pioneering Image Recognition to intuitive Chatbot Assistants, all powered by the latest in artificial intelligence to enrich user interactions. <br/><br/>
Our Speech-to-Text offerings ensure that communication effortlessly, breaking down barriers and opening doors to new possibilities
We empower organizations with seamless Continuous Integration and Continuous Delivery, streamlining software development for peak efficiency. <br/><br/>
Our web development prowess encompasses everything from sleek Frontend and robust Backend Development to comprehensive Full Stack Development, all executed with an eye for design and security.
Our commitment to quality is unwavering, with a Quality Assurance team that guarantees top-tier software through diverse testing methods and prioritizing client satisfaction as we navigate the digital terrain together.
            </HeroSub>
          </HeroTextWrapper>
        </HeroContainer>
      </animated.div>
      <animated.div style={animationValues} ref={refValues}>
        <AboutCards Values={values} Heading='Our Values' />
      </animated.div>
      <animated.div style={animationFocus} ref={refFocus}>
      <AboutCards Values={focus} Heading='Our Focus' />
      </animated.div>
      <animated.div style={animationContact} ref={refContact}>
        <Contact />
      </animated.div>
      <animated.div style={animationFooter} ref={refFooter}>
        <Footer />
      </animated.div>
    </div>
  );
}

export default Aboutus;
