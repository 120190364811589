import React from 'react'
import {
    AboutCard,
    AboutCards,
    AboutHead,
    AboutHeading,
    AboutSection,
    AboutSub,
    AboutTextSub,
  } from "./About.styles";
import { HeroButton } from '../Hero/hero.styles';

function About({theme, ...Aboutdata}) {
    const {Services,cardData}=Aboutdata
  return (
    <AboutSection theme={theme}>
      <AboutHeading theme={theme}>{Services.heading}</AboutHeading>
      <AboutSub theme={theme}>{Services.subText}</AboutSub>
      <AboutCards theme={theme}>
        {cardData.map((card) => (
          <AboutCard key={card.id} theme={theme}>
            <AboutHead theme={theme}>{card.heading}</AboutHead>
            <AboutTextSub theme={theme}>{card.subText}</AboutTextSub>
          </AboutCard>
        ))}
      </AboutCards>
      <HeroButton to="/ContactUs" style={{width:"70%"}} >Request Our Services</HeroButton>
    </AboutSection>
  )
}

export default About
