import React from 'react';
import {
  ServiceCard,
  ServiceCardDesc,
  ServiceCardHeading,
  ServiceDiv,
  ServiceHead,
  ServiceImage,
  ServiceText,
  ServiceTextSub,
  ServicesContainer,
  ServicesHeading
} from './services.styles';
import { AboutCard, AboutCards, AboutHead, AboutTextSub } from '../About/About.styles';
import { HeroButton } from '../Hero/hero.styles';

function Services({ ...ServicesData }) {
  const servicesRows = [];
  for (let i = 0; i < ServicesData.services.length; i += 2) {
    const row = ServicesData.services.slice(i, i + 2);
    servicesRows.push(row);
  }

  return (
    <ServicesContainer>
      <ServicesHeading>Various Services we provide</ServicesHeading>
      <ServiceDiv>
        {servicesRows.map((row, rowIndex) => (
          <AboutCards key={rowIndex}>
            {row.map(service => (
              <ServiceCard key={service.name} className='prog-imgbx'>
                <ServiceImage src={service.image} />
                <ServiceText className='prog-txtx'>
                  <ServiceHead>{service.name}</ServiceHead>
                  <ServiceTextSub>{service.description}</ServiceTextSub>
                </ServiceText></ServiceCard>
            ))}
          </AboutCards>
        ))}
      </ServiceDiv>
      <HeroButton to="/ContactUs" style={{ width: "80%" }}>Talk to us</HeroButton>
    </ServicesContainer>
  );
}

export default Services;
