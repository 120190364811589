import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Dark, Colors as colors } from "../../utils/Colors"; // Importing colors from colors.js

export const BlogsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background: ${(props) => props.theme === true ? `linear-gradient(to ${props.direction || 'top'}, #004ff9, #000000)` : "white"};
  padding:50px;
  height:100%;
  margin-bottom:10px;
  ${media("medium")} {
    flex-direction:column;
    padding:10px;
    height:100%;
  }
  ${media("small")} {
    height:100%;
    padding:10px;
    flex-direction:column;
  }
`;

export const BlogsSub = styled.p`
  font-family: "Outfit";
  font-weight: bold;
  font-size: 20px;
  margin-bottom: -50px;
  color: ${(props)=>props.theme==true? Dark.BlogHeading:colors.BlogsSubText}; // Using the new color
  text-align: center;
  ${media("medium")} {
    font-size: 18px;
    margin-bottom: -40px;
  }
  ${media("small")} {
    font-size: 15px;
    margin-bottom: -20px;
  }
`;

export const BlogsHeading = styled.p`
  font-family: "Outfit";
  font-weight: bold;
  font-size: 50px;
  color:  ${(props)=>props.theme==true? Dark.BlogHeading:colors.Text};
  text-align: center;
  ${media("medium")} {
    font-size: 40px;
  }
  ${media("small")} {
    font-size: 35px;
  }
`;

export const BlogInner = styled.div`
  display:flex;
  flex-direction:row;
  height:500px;
  gap:120px;
  align-items:center;
  justify-content:space-evenly;
  ${media("medium")} {
    flex-direction:column;
    gap:50px;
    padding:20px;
    height:100%;
  }
  ${media("small")} {
    height:100%;
    gap:50px;
    padding:20px;
    flex-direction:column;
  }
`;

export const BlogCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items:center;
  background-color:white;
  height:580px;
  margin-left:-18px;
  border-radius:25px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  justify-content:space-evenly;
  padding:15px;
  ${media("medium")} {
    height:580px;
  margin-left:-15px;
  }
  ${media("small")} {
    height:580px;
  margin-left:-15px;
  }
`;

export const BlogImage = styled.img`
  width: 100%;
  height:250px;
  ${media("medium")} {
    width: 100%;
    height:150px;
  }
  ${media("small")} {
    height:150px;
    width: 100%;
  }
`;

export const BlogText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media("medium")} {
    width: 100%;
  }
  ${media("small")} {
    width: 100%;
  }
`;

export const BlogHeading = styled.span`
  font-family: "Outfit";
  font-weight: bold;
  font-size: 30px;
  color: ${colors.Text};
  ${media("medium")} {
    font-size: 25px;
  }
  ${media("small")} {
    font-size: 20px;
  }
`;

export const BlogSub = styled.span`
  font-family: "Outfit";
  font-weight: normal;
  font-size: 18px;
  color: ${colors.Text};
  text-align: justify;
  ${media("medium")} {
    font-size: 15px;
  }
  ${media("small")} {
    font-size: 13px;
  }
`;

export const BLogsBack = styled.img`
  position:relative;
  margin-left:100%;
  margin-top:-30%;
  z-index:-1;
  ${media("medium")} {
    margin-left:100%;
    margin-top:-80%;
  }
  ${media("small")} {
    margin-left:100%;
    margin-top:-150%;
  }
`;
