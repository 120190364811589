import React, { useState, useEffect, useContext } from "react";
import { useDarkMode } from "../../DarkModeContext";
import {
  LeftContainer,
  NavbarContainer,
  NavbarLinkContainer,
  NavbarLink,
  RightContainer,
  StyledLink,
  Logo,
  DropdownContainer,
  DropdownItem,
  DropdownExtendedContainer,
  NavbarInnerContainer,
  NavbarExtendedContainer,
  NavbarLinkExtended,
  OpenLinksButton,
  ContactExtendedButton,
  ContactButton,
} from "./Header.styles";
import logo from "../../Assets/Images/Logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import arrowdown from "../../Assets/Images/Vectorarrowdown.png";
import arrowup from "../../Assets/Images/Vectorarrowup.png";
import DarkModeToggle from "../../DarkModeToggle";

function Header() {
  const { isDarkMode } = useDarkMode(); // Access dark mode state from context

  const [isDropdownOpen1, setDropdownOpen1] = useState(false);
  const [extendNavbar, setExtendNavbar] = useState(false);
  const [isDropdownOpen2, setDropdownOpen2] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const toggleDropdown1 = () => {
    setDropdownOpen1(!isDropdownOpen1);
  };

  const toggleDropdown2 = () => {
    setDropdownOpen2(!isDropdownOpen2);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos === 0);
      setPrevScrollPos(currentScrollPos);
      setExtendNavbar(false);
      setDropdownOpen2(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <NavbarContainer visible={visible} extendNavbar={extendNavbar} dropdown={isDropdownOpen2} theme={isDarkMode}>
      <NavbarInnerContainer theme={isDarkMode} extendNavbar={extendNavbar}>
        <LeftContainer>
          <Logo src={logo} width={120} />
          <StyledLink to={"/"} theme={isDarkMode}>BASETECH</StyledLink>
          <DarkModeToggle  /> {/* Pass dark mode state as a prop */}
        </LeftContainer>
        <RightContainer>
          <NavbarLinkContainer theme={isDarkMode}>
            <NavbarLink to="/" theme={isDarkMode}><FontAwesomeIcon icon={faHome} /></NavbarLink>
            {/* <NavbarLink
              onMouseEnter={() => {
                toggleDropdown1();
                setDropdownOpen2(false);
              }}
              onMouseLeave={() => {
                toggleDropdown1();
              }}
              onClick={() => {
                toggleDropdown1();
                setDropdownOpen2(false);
              }}
              theme={isDarkMode}
            >
              Services{" "}
              <img src={isDropdownOpen1 ? arrowup : arrowdown} />
            </NavbarLink>
            {isDropdownOpen1 && (
              <DropdownContainer
                visible={visible}
                onMouseEnter={() => setDropdownOpen1(true)}
                onMouseLeave={() => setDropdownOpen1(false)}
                theme={isDarkMode}
              >
                <DropdownItem to="/Ai" theme={isDarkMode}>Artificial Intelligence</DropdownItem>
                {/* <DropdownItem to="/DevOps" theme={isDarkMode}>DevOps</DropdownItem> }
                <DropdownItem to="/WebDev" theme={isDarkMode}>Web Development</DropdownItem>
                <DropdownItem to="/Qa" theme={isDarkMode}>Quality Assurance</DropdownItem>
              </DropdownContainer>
            )} */}
            <NavbarLink to="/Ai" theme={isDarkMode}>AI</NavbarLink>
            <NavbarLink to="/WebDev" theme={isDarkMode}>Web</NavbarLink>
            <NavbarLink to="/Qa" theme={isDarkMode}>QA</NavbarLink>
            {/* <NavbarLink to="/CaseStudies" theme={isDarkMode}>Case Studies</NavbarLink> */}
            <NavbarLink to="/Aboutus" theme={isDarkMode}>About Us</NavbarLink>
            <ContactButton to="/ContactUs" theme={isDarkMode}>Contact Us</ContactButton>
            <OpenLinksButton
              onClick={() => {
                setExtendNavbar((curr) => !curr);
              }}
              theme={isDarkMode}
            >
              {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
            </OpenLinksButton>
          </NavbarLinkContainer>
        </RightContainer>
      </NavbarInnerContainer>
      {extendNavbar && (
        <NavbarExtendedContainer theme={isDarkMode} >
          <NavbarLinkExtended to="/" theme={isDarkMode}><FontAwesomeIcon icon={faHome} /></NavbarLinkExtended>
          {/* <NavbarLinkExtended
            onClick={() => {
              toggleDropdown2();
              setDropdownOpen1(false);
            }}
            theme={isDarkMode}
          >
            Services{" "}
            <img src={isDropdownOpen2 ? arrowup : arrowdown} />
          </NavbarLinkExtended>
          {isDropdownOpen2 && (
            <DropdownExtendedContainer visible={visible} theme={isDarkMode}>
              <DropdownItem to="/Ai" theme={isDarkMode}>Artificial Intelligence</DropdownItem>
              {/* <DropdownItem to="/DevOps" theme={isDarkMode}>DevOps</DropdownItem> }
              <DropdownItem to="/WebDev" theme={isDarkMode}>Web Development</DropdownItem>
              <DropdownItem to="/Qa" theme={isDarkMode}>Quality Assurance</DropdownItem>
            </DropdownExtendedContainer>
          )} */}
          {/* <NavbarLinkExtended to="/CaseStudies" theme={isDarkMode}>Case Studies</NavbarLinkExtended> */}
          <NavbarLinkExtended to="/Ai" theme={isDarkMode}>AI</NavbarLinkExtended>
          <NavbarLinkExtended to="/WebDev" theme={isDarkMode}>Web-Development</NavbarLinkExtended>
          <NavbarLinkExtended to="/Qa" theme={isDarkMode}>QA</NavbarLinkExtended>
          <NavbarLinkExtended to="/Aboutus" theme={isDarkMode}>About Us</NavbarLinkExtended>
          <ContactExtendedButton to="/ContactUs" theme={isDarkMode}>
            <span style={{ zIndex: "99999" }}>Contact Us</span>
          </ContactExtendedButton>
        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  );
}

export default Header;
