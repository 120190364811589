import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import GlobalStyle from './Styles/Globals';
import Home from './pages/Home';
import AI from './pages/AI';
import DevOps from './pages/DevOps';
import WebDev from './pages/WebDev';
import Qa from './pages/Qa';
import CaseStudies from './pages/CaseStudies';
import Aboutus from './pages/Aboutus';
import CaseStudyPage from './pages/CaseStudy';
import { DarkModeProvider } from './DarkModeContext';
import ContactPage from './pages/Contact';
import VerifyCertificate from './pages/VerfiyCertificate';
import Certificate from './pages/Certificate';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';
import { SpeedInsights } from "@vercel/speed-insights/react";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  return (
    <DarkModeProvider>
      <div className="App">
        <GlobalStyle />
        <BrowserRouter>
          <ScrollToTop /> {/* Include the ScrollToTop component */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/CaseStudies" element={<CaseStudies />} />
            <Route path="/Ai" element={<AI />} />
            {/* <Route path="/DevOps" element={<DevOps />} /> */}
            <Route path="/WebDev" element={<WebDev />} />
            <Route path="/Qa" element={<Qa />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/ContactUs" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<Privacy/>} />
            <Route path="/CaseStudy/:blogheadline" element={<CaseStudyPage />} />
            <Route path="/verifyCertificate" element={<VerifyCertificate />} />
            <Route path="/verifyCertificate/:certificateId" element={<Certificate />} />
            <Route path="*" element={<NotFound/>} /> {/* Catch-all route for 404 */}
          </Routes>
        </BrowserRouter>
	<SpeedInsights/>
      </div>
    </DarkModeProvider>
  );
}

export default App;
