import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Link } from "react-router-dom";
import back from "../../Assets/Images/mediumback.jpg";
import dark from "../../Assets/Images/herobackdarkimage.jpg";
import {Dark, Colors as  colors } from "../../utils/Colors";

export const HeroContainer=styled.div`
padding-bottom:150px;
background-image:${(props)=>props.theme==true && `url(${dark})`};
background-size:${(props)=>props.theme==true && "cover"};
padding-top:150px;
${media("medium")} {
    padding-bottom:0px;
    background-image:${(props)=>props.theme==true? `url(${dark})`:`url(${back})`};
    background-size:cover;
  }
  ${media("small")} {
    padding-bottom:0px;
    background-image:${(props)=>props.theme==true? `url(${dark})`:`url(${back})`};
    backgroudn-size:cover;
  }
`;
export const HeroSub = styled.span`
display:flex;
  font-size: 18px;
  font-weight: normal;
  font-family: "Outfit";
  padding-top:20px;
  color:${(props)=> props.theme==true?Dark.NavLinkColor:colors.NavLinkColor};
  padding-bottom:40px;
  width: 750px;
  ${media("medium")} {
    font-size:18px;
    width:80%;
    overflow:hidden;
  }
  ${media("small")} {
    font-size:15px;
    padding-bottom:20px;
  }
`;
export const HeroButton = styled(Link)`
background:${(props)=> props.theme==true?Dark.ContactButtonColor:colors.ContactButtonColor};
color: ${(props)=> props.theme==true?Dark.ButtonText:colors.ButtonText};;
font-family: "Outfit";
text-decoration:none;
font-size: 20px;
text-align:center;
cursor:pointer;
font-weight: bold;
border-radius: 15px;
padding:20px;
transition: 0.2s ease-in;
  &:hover {
    background:${(props)=> props.theme==true?Dark.ButtonHover:colors.ButtonHover};
    color:${(props)=> props.theme==true?Dark.HoverButtonText:colors.ButtonText};
  }
  ${media("medium")} {
    padding:15px;
  }
  ${media("small")} {
    padding:10px;
    font-size:15px;
  }
`;
export const HeroBack=styled.img`
position:relative;
width:55%;
z-index:-1;
margin-left:40%;
margin-bottom:-33%;
overflow:hidden;
${media("medium")} {
    display:none;
  }
  ${media("small")} {
    display:none;
  }
`;
export const HeroBackDots=styled.img`
position:relative;
width:300px;
left:35%;
margin-top:-5%;
z-index:-2;
filter:${(props)=>props.theme==true? "invert(1)":"none"};
display:${(props)=>props.theme==true && "none"};
${media("medium")} {
    display:none;
  }
  ${media("small")} {
    display:none;
  }
`;