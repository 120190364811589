// import React, { useRef, useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
// import { TestimonialCard, TestimonialContainer, TestimonialImage, TestimonialName, TestimonialText, Testimonialtextcontainer } from '../Testimonials/Testimonial.styles';
// import { BlogCard, BlogHeading, BlogImage, BlogInner, BlogSub, BlogText, BlogsContainer, BlogsHeading, BlogsSub } from '../Blogs/blogs.styles';
// import testimonialsdata from "./SliderInfo.json";
// import Arrow from "../../Assets/Images/arrow.svg";
// import ArrowFilled from "../../Assets/Images/ArrowBlack.svg";
// import { useDarkMode } from '../../DarkModeContext';
// import { SliderCard, SliderImage, SliderImageCont } from './Slider.styles';

// const Autoplay = () => {
//   const sliderRef = useRef(null); // Create a ref for the Slider component
//   const [hoveredArrow, setHoveredArrow] = useState(null);
//   useEffect(() => {
//     const style = document.createElement("style");
//     style.textContent = `
//       @media (min-width: 768px) {
//         .slick-track {
//           padding: 30px;
//           height:650px
//         }
//         .TestimonialCont{
//           flex-direction:row;
//           justify-content:flex-start;
//         }
//       }
//       @media (max-width: 767px) {
//         .slick-track {
//           padding: 0;
//           height:100%;
//         }
//         .TestimonialCont{
//           flex-direction:column;
//           gap:5px;
//         }
//       }
//     `;
//     document.head.appendChild(style);

//     return () => {
//       document.head.removeChild(style);
//     };
//   }, []);
//   const renderArrows = () => {
//     return (
//       <div className="slider-arrow">
//         <img
//           src={hoveredArrow === "left" ? ArrowFilled : Arrow}
//           alt="Left Arrow"
//           style={{ width: '54px', height: '54px', cursor: 'pointer', marginRight: '10px', borderRadius: '50%',filter: isDarkMode ? "invert(1)" : "none" }}
//           onClick={() => sliderRef.current.slickPrev()}
//           className="arrow-img"
//           onMouseEnter={() => setHoveredArrow("left")}
//           onMouseLeave={() => setHoveredArrow(null)}
//         />
//         <img
//           src={hoveredArrow === "right" ? ArrowFilled : Arrow}
//           alt="Right Arrow"
//           style={{ width: '54px', height: '54px', cursor: 'pointer', transform: "rotate(180deg)", borderRadius: '50%',filter: isDarkMode ? "invert(1)" : "none" }}
//           onClick={() => sliderRef.current.slickNext()}
//           className="arrow-img"
//           onMouseEnter={() => setHoveredArrow("right")}
//           onMouseLeave={() => setHoveredArrow(null)}
//         />
//       </div>
//     );
//   };
//   const sliderWidth = window.innerWidth >= 768 ? "65%" : "100%";
//   const { isDarkMode } = useDarkMode();
//   const settings = {
//     infinite: true,
//     autoplay: true,
//     speed: 800,
//     centerMode: true, // Center mode to center the slide
//     centerPadding: "0px", // No padding on the sides
//     autoplaySpeed: 3500,
//     cssEase: "linear",
//     dots: true,
//     slidesToShow: 1, // Show 3 slides by default
//     arrows: false, // Hide arrows
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 1,
//           arrows: false, // Hide arrows on smaller screens
//           centerMode: true, // Enable center mode for smaller screens
//           centerPadding: "0px", // No padding on the sides
//         },
//       },
//     ],
//   };

//   return (
//     <BlogsContainer theme={isDarkMode} className='TestimonialCont'>
//       {window.innerWidth < 768 && <BlogsHeading style={{ width: "80%" }} theme={isDarkMode} >Services We Offer<BlogsSub theme={isDarkMode}>"Delivering intuitive user interfaces, optimized data storage, and streamlined data processing for enhanced digital experiences and informed decision-making."</BlogsSub><br />
//         {window.innerWidth >= 768 && renderArrows()} {/* Render custom arrows */}</BlogsHeading>}
//       <Slider theme={isDarkMode} {...settings} style={{ width: sliderWidth, display: "flex" }} ref={sliderRef}>
//         {testimonialsdata && Array.isArray(testimonialsdata) && testimonialsdata.map((testimonies, index) => (
//           <BlogInner key={index} theme={isDarkMode}>
//             <SliderCard key={index} theme={isDarkMode} style={{ width: "90%", flexDirection: "column" }}>
//               <BlogText theme={isDarkMode} style={{ width: "90%" }}>
//                 <BlogHeading>{testimonies.quote}</BlogHeading>
//                 <br />
//                 <TestimonialText>{testimonies.testimony}</TestimonialText><br /><br />
//                 <BlogSub><b>Technologies we use:</b></BlogSub>
//               </BlogText>
//               <SliderImageCont>
//                 {testimonies.images && Array.isArray(testimonies.images) && testimonies.images.map((image, imgIndex) => (
//                   <SliderImage key={imgIndex} src={image} alt={`Image ${imgIndex}`} />
//                 ))}
//               </SliderImageCont>
//             </SliderCard>
//           </BlogInner>
//         ))}

//       </Slider>
//       {window.innerWidth >= 768 && <BlogsHeading style={{ width: "80%",color: isDarkMode? "white":"inherit" }}>Services We Offer<BlogsSub style={{color: isDarkMode? "white":"inherit"}}>"Delivering intuitive user interfaces, optimized data storage, and streamlined data processing for enhanced digital experiences and informed decision-making."</BlogsSub><br />
//         {window.innerWidth >= 768 && renderArrows()} {/* Render custom arrows */}</BlogsHeading>}<br /><br />
//       {window.innerWidth < 768 && renderArrows()}
//     </BlogsContainer>
//   );
// };

// export default Autoplay;

import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Tooltip } from 'react-tooltip'; // Importing tooltip component
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { TestimonialCard, TestimonialContainer, TestimonialImage, TestimonialName, TestimonialText, Testimonialtextcontainer } from '../Testimonials/Testimonial.styles';
import { BlogCard, BlogHeading, BlogImage, BlogInner, BlogSub, BlogText, BlogsContainer, BlogsHeading, BlogsSub } from '../Blogs/blogs.styles';
import testimonialsdata from "./SliderInfo.json";
import Arrow from "../../Assets/Images/arrow.svg";
import ArrowFilled from "../../Assets/Images/ArrowBlack.svg";
import { useDarkMode } from '../../DarkModeContext';
import { SliderCard, SliderImage, SliderImageCont } from './Slider.styles';

const Autoplay = () => {
  const sliderRef = useRef(null); // Create a ref for the Slider component
  const [hoveredArrow, setHoveredArrow] = useState(null);
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      @media (min-width: 768px) {
        .slick-track {
          padding: 30px;
          height:650px
        }
        .TestimonialCont{
          flex-direction:row;
          justify-content:flex-start;
        }
      }
      @media (max-width: 767px) {
        .slick-track {
          padding: 0;
          height:100%;
        }
        .TestimonialCont{
          flex-direction:column;
          gap:5px;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);
  const renderArrows = () => {
    return (
      <div className="slider-arrow">
        <img
          src={hoveredArrow === "left" ? ArrowFilled : Arrow}
          alt="Left Arrow"
          style={{ width: '54px', height: '54px', cursor: 'pointer', marginRight: '10px', borderRadius: '50%',filter: isDarkMode ? "invert(1)" : "none" }}
          onClick={() => sliderRef.current.slickPrev()}
          className="arrow-img"
          onMouseEnter={() => setHoveredArrow("left")}
          onMouseLeave={() => setHoveredArrow(null)}
        />
        <img
          src={hoveredArrow === "right" ? ArrowFilled : Arrow}
          alt="Right Arrow"
          style={{ width: '54px', height: '54px', cursor: 'pointer', transform: "rotate(180deg)", borderRadius: '50%',filter: isDarkMode ? "invert(1)" : "none" }}
          onClick={() => sliderRef.current.slickNext()}
          className="arrow-img"
          onMouseEnter={() => setHoveredArrow("right")}
          onMouseLeave={() => setHoveredArrow(null)}
        />
      </div>
    );
  };
  const sliderWidth = window.innerWidth >= 768 ? "65%" : "100%";
  const { isDarkMode } = useDarkMode();
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 800,
    centerMode: true, // Center mode to center the slide
    centerPadding: "0px", // No padding on the sides
    autoplaySpeed: 3500,
    cssEase: "linear",
    dots: true,
    slidesToShow: 1, // Show 3 slides by default
    arrows: false, // Hide arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false, // Hide arrows on smaller screens
          centerMode: true, // Enable center mode for smaller screens
          centerPadding: "0px", // No padding on the sides
        },
      },
    ],
  };

  return (
    <BlogsContainer theme={isDarkMode} className='TestimonialCont'>
      {window.innerWidth < 768 && <BlogsHeading style={{ width: "80%" }} theme={isDarkMode} >Services We Offer<BlogsSub theme={isDarkMode}>"Delivering intuitive user interfaces, optimized data storage, and streamlined data processing for enhanced digital experiences and informed decision-making."</BlogsSub><br />
        {window.innerWidth >= 768 && renderArrows()} {/* Render custom arrows */}</BlogsHeading>}
      <Slider theme={isDarkMode} {...settings} style={{ width: sliderWidth, display: "flex" }} ref={sliderRef}>
        {testimonialsdata && Array.isArray(testimonialsdata) && testimonialsdata.map((testimonies, index) => (
          <BlogInner key={index} theme={isDarkMode}>
            <SliderCard key={index} theme={isDarkMode} style={{ width: "90%", flexDirection: "column" }}>
              <BlogText theme={isDarkMode} style={{ width: "90%" }}>
                <BlogHeading>{testimonies.quote}</BlogHeading>
                <br />
                <TestimonialText>{testimonies.testimony}</TestimonialText><br /><br />
                <BlogSub><b>Technologies we use:</b></BlogSub>
              </BlogText>
              <SliderImageCont>
                {testimonies.images && Array.isArray(testimonies.images) && testimonies.images.map((image, imgIndex) => (
                  <React.Fragment key={imgIndex}>
                    <SliderImage data-tooltip-id={`tooltip-${index}-${imgIndex}`} key={imgIndex} src={image} alt={`Image ${imgIndex}`} />
                    <Tooltip id={`tooltip-${index}-${imgIndex}`} effect="solid" place="bottom">
                      {testimonies.tech[imgIndex]}
                    </Tooltip>
                  </React.Fragment>
                ))}
              </SliderImageCont>
            </SliderCard>
          </BlogInner>
        ))}
      </Slider>
      {window.innerWidth >= 768 && <BlogsHeading style={{ width: "80%",color: isDarkMode? "white":"inherit" }}>Services We Offer<BlogsSub style={{color: isDarkMode && "white"}}>"Delivering intuitive user interfaces, optimized data storage, and streamlined data processing for enhanced digital experiences and informed decision-making."</BlogsSub><br />
        {window.innerWidth >= 768 && renderArrows()} {/* Render custom arrows */}</BlogsHeading>}<br /><br />
      {window.innerWidth < 768 && renderArrows()}
    </BlogsContainer>
  );
};

export default Autoplay;

