import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { CertifiedText, TickImage, VerifiedText, VerifyContainer } from './styles/VerifyCertificate.styles';
import VerifiedIcon from '@mui/icons-material/Verified';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Certificate() {
    const { certificateId } = useParams();
    const [internData, setInternData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(()=>{
        fetchdata();
    },[certificateId])

    const fetchdata = async () => {
        try {
            const response = await fetch(
                process.env.REACT_APP_BACKEND_URL + `validate-certificate?certificate_id=${certificateId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "ngrok-skip-browser-warning": "69420",
                    }
                }
            );
            if (!response.ok) {
                throw new Error("Certificate Not Found");
            }
            const responseData = await response.json();
            console.log(responseData);
            setInternData(responseData);
        } catch (error) {
            console.log(error);
            setError(error.message);
        }
    };
    
    return (
        <div>
            <Header /><br /><br /><br /><br /><br /><br />
            <VerifyContainer>
                {internData ? (
                    <>
                        <TickImage><VerifiedIcon style={{ width: "100%", height: "100%", color: "#64c9dd" }} /></TickImage>
                        <VerifiedText>Congratulations! The certificate has been verified</VerifiedText>
                        <CertifiedText>This certificate is issued by BaSeTech to</CertifiedText><br /><br />
                        <TableContainer style={{ width: window.innerWidth >= 768 ? "50%" : "100%", border: "1px solid #000" }}>
                            <Table aria-label="intern table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: "bold", borderRight: "1px solid #000" }}>Name</TableCell>
                                        <TableCell align="center">{internData.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: "bold", borderRight: "1px solid #000" }}>Start Date</TableCell>
                                        <TableCell align="center">{internData.start_date}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: "bold", borderRight: "1px solid #000" }}>End Date</TableCell>
                                        <TableCell align="center">{internData.end_date}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: "bold", borderRight: "1px solid #000" }}>Role</TableCell>
                                        <TableCell align="center">{internData.role}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: "bold", borderRight: "1px solid #000" }}>Review</TableCell>
                                        <TableCell align="center">{internData.review}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" style={{ fontWeight: "bold", borderRight: "1px solid #000" }}>Rating</TableCell>
                                        <TableCell align="center">{internData.rating}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <>
                        <TickImage><DangerousIcon style={{ width: "100%", height: "100%", color: "red" }} /></TickImage>
                        <VerifiedText style={{ color: "red" }}>{error || 'Certificate not found'}</VerifiedText>
                    </>
                )}
            </VerifyContainer><br /><br />
            <Footer />
        </div>
    );
}

export default Certificate;
