import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import AutoPlay from "../components/Slider/Slider";
import Hero from "../components/Hero/Hero";
import { Link } from "react-router-dom";
import {
  TextWrapper,
  TextP,
  Words,
  Span,
} from "../components/RotatingText/RotatingText.styles";
import { HeroSub, HeroButton } from "../components/Hero/hero.styles";
import {
  BelowBack,
  BelowSlider,
  BelowSliderImage,
  BelowSliderText,
  BelowBlogs,
  BelowBlogText,
  BelowText,
} from "./styles/Home.styles";
import businessback from "../Assets/Images/business.jpg";
import belowback from "../Assets/Images/Belowback.png";
import Testimonials from "../components/Testimonials/Testimonials";
import Blogs from "../components/Blogs/Blogs";
import belowblogback from "../components/Blogs/blog1.jpg";
import Info from "../components/Info/info";
import { useDarkMode } from "../DarkModeContext";
import { useSpring, animated } from 'react-spring';

function Home() {
  const { isDarkMode } = useDarkMode();

  const [isVisibleHero, setIsVisibleHero] = useState(true);
  const refHero = useRef();

  const animationHero = useSpring({
    opacity: isVisibleHero ? 1 : 0,
    transform: isVisibleHero ? 'translateY(0)' : 'translateY(50px)',
  });

  const [isVisibleAutoPlay, setIsVisibleAutoPlay] = useState(false);
  const refAutoPlay = useRef();
  const animationAutoPlay = useSpring({
    opacity: isVisibleAutoPlay ? 1 : 0,
    transform: isVisibleAutoPlay ? 'translateY(0)' : 'translateY(70px)',
  });

  const [isVisibleTestimonials, setIsVisibleTestimonials] = useState(false);
  const refTestimonials = useRef();

  const animationTestimonials = useSpring({
    opacity: isVisibleTestimonials ? 1 : 0,
    transform: isVisibleTestimonials ? 'translateY(0)' : 'translateY(70px)',
  });

  const [isVisibleInfo, setIsVisibleInfo] = useState(false);
  const refInfo = useRef();

  const animationInfo = useSpring({
    opacity: isVisibleInfo ? 1 : 0,
    transform: isVisibleInfo ? 'translateY(0)' : 'translateY(70px)',
  });

  const [isVisibleSlider, setIsVisibleSlider] = useState(false);
  const refSlider = useRef();

  const animationSlider = useSpring({
    opacity: isVisibleSlider ? 1 : 0,
    transform: isVisibleSlider ? 'translateY(0)' : 'translateY(70px)',
  });

  const [isVisibleBlogs, setIsVisibleBlogs] = useState(false);
  const refBlogs = useRef();

  const animationBlogs = useSpring({
    opacity: isVisibleBlogs ? 1 : 0,
    transform: isVisibleBlogs ? 'translateY(0)' : 'translateY(70px)',
  });

  const [isVisibleFooter, setIsVisibleFooter] = useState(false);
  const refFooter = useRef();

  const animationFooter = useSpring({
    opacity: isVisibleFooter ? 1 : 0,
    transform: isVisibleFooter ? 'translateY(0)' : 'translateY(70px)',
  });
  useEffect(() => {
    // Check screen size and set isVisibleAutoPlay accordingly
    const handleResize = () => {
      const isMediumOrSmall = window.innerWidth <= 1024; // Define your medium or small size threshold
      setIsVisibleAutoPlay(isMediumOrSmall);
    };

    // Call handleResize initially
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleScroll = () => {

    if (refAutoPlay.current) {
      const topAutoPlay = refAutoPlay.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleAutoPlay(topAutoPlay < windowHeight * 0.8);
    }
    if (refTestimonials.current) {
      const topTestimonials = refTestimonials.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleTestimonials(topTestimonials < windowHeight * 0.8);
    }
    if (refInfo.current) {
      const topInfo = refInfo.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleInfo(topInfo < windowHeight * 0.8);
    }
    if (refSlider.current) {
      const topSlider = refSlider.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleSlider(topSlider < windowHeight * 0.8);
    }
    if (refBlogs.current) {
      const topBlogs = refBlogs.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleBlogs(topBlogs < windowHeight * 0.8);
    }
    if (refFooter.current) {
      const topFooter = refFooter.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleFooter(topFooter < windowHeight * 0.8);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page with animated transition on page load
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);
    // Remove event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <Header />
      <animated.div style={animationHero} ref={refHero}>
        <Hero />
      </animated.div>
      <animated.div style={animationAutoPlay} ref={refAutoPlay}>
        <AutoPlay />
      </animated.div>
      <animated.div style={animationSlider} ref={refSlider}>
        <BelowSlider theme={isDarkMode}>
          <BelowBack src={belowback} theme={isDarkMode} />
          <BelowSliderImage theme={isDarkMode}>
            <img src={businessback} width={650} alt="Business Background" />
          </BelowSliderImage>
          <BelowSliderText theme={isDarkMode}>
            <TextWrapper style={{ padding:window.innerWidth>768? "20px 50px":"10", width: "100%" }}>
              <TextP theme={isDarkMode}>
                Together, We Craft Your project
              </TextP>
              <Words style={{ marginLeft: "-10px", marginTop: "-35px" }} theme={isDarkMode}>
                <Span theme={isDarkMode}>Swiftly</Span>
                <Span theme={isDarkMode}>With care</Span>
                <Span theme={isDarkMode}>To perfection</Span>
              </Words>
              <HeroSub theme={isDarkMode}>
                At Basetech, we understand that your vision deserves personalized attention and expertise. By actively listening and providing tailored advice, we craft and deliver exceptional, user-centric IT solutions. Our agile approach, enriched by our wealth of project experience and collective wisdom, ensures we add real value to our clients' endeavors. Discover the Basetech difference, where your success is our mission.
              </HeroSub>
              <HeroButton to="/ContactUs" theme={isDarkMode}>Let's Talk</HeroButton>
            </TextWrapper>
          </BelowSliderText>
        </BelowSlider>
      </animated.div>
      <animated.div style={animationTestimonials} ref={refTestimonials}>
        <Testimonials theme={isDarkMode} />
      </animated.div>
      <animated.div style={animationInfo} ref={refInfo}>
        <Info theme={isDarkMode} />
      </animated.div>
      <animated.div style={animationBlogs} ref={refBlogs}>
        <BelowBlogs theme={isDarkMode}>
          <BelowBack src={belowback} theme={isDarkMode} />
          <BelowSliderText theme={isDarkMode}>
            <TextWrapper style={{ paddingLeft: window.innerWidth>=768? 30: 10, width: "100%" }} theme={isDarkMode}>
              <TextP theme={isDarkMode}>
                Let's build something that can bring a change
              </TextP>
              <HeroButton to="/ContactUs" theme={isDarkMode}>Let's Talk</HeroButton>
            </TextWrapper>
          </BelowSliderText>
          <BelowSliderImage theme={isDarkMode}>
            <img
              src={belowblogback}
              width={650}
              style={{ marginTop: "80px" }}
              alt="Below Blog Background"
            />
          </BelowSliderImage>
          <BelowBlogText theme={isDarkMode}>
            <BelowText theme={isDarkMode}>5+<br /> Projects</BelowText>
            <BelowText theme={isDarkMode}>3+<br /> clients</BelowText>
            <BelowText theme={isDarkMode}>3500+<br /> hours</BelowText>
          </BelowBlogText>
        </BelowBlogs>
      </animated.div>
      <animated.div style={animationFooter} ref={refFooter}>
        <Footer theme={isDarkMode} />
      </animated.div>
    </>
  );
}

export default Home;
