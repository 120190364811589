import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Dark, Colors as colors } from "../../utils/Colors"; // Importing colors from Colors.js

export const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background: ${(props)=> props.theme==true? Dark.AboutSectionBackground:colors.AboutSectionBackground}; // Using the new color for AboutSection background
  ${media("medium")} {
    padding: 20px;
  }
  ${media("small")} {
    padding: 20px;
  }
`;

export const AboutHeading = styled.span`
  text-align: center;
  font-family: 'Outfit';
  font-size: 35px;
  font-weight: bold;
  padding: 10px;
  ${media("medium")} {
    font-size: 30px;
  }
  ${media("small")} {
    font-size: 25px;
  }
`;

export const AboutSub = styled.span`
  font-family: 'Outfit';
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  padding: 10px;
  padding-bottom: 30px;
  ${media("medium")} {
    font-size: 18px;
  }
  ${media("small")} {
    font-size: 15px;
  }
`;

export const AboutCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 30px;
  ${media("medium")} {
    flex-direction: column;
    gap:50px;
  }
  ${media("small")} {
    flex-direction: column;
    gap:50px;
  }
`;

export const AboutCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background: ${(props)=> props.theme==true? Dark.AboutCardBackground:colors.AboutCardBackground}; // Using the new color for AboutCard background
`;

export const AboutHead = styled.span`
  text-align: left;
  font-family: 'Outfit';
  font-size: 25px;
  font-weight: bold;
  padding-top: 30px;
  ${media("medium")} {
    font-size: 20px;
  }
  ${media("small")} {
    font-size: 17px;
  }
`;

export const AboutTextSub = styled.div`
  text-align: left;
  font-family: 'Outfit';
  font-size: 20px;
  font-weight: normal;
  padding: 30px;
  ${media("medium")} {
    font-size: 18px;
  }
  ${media("small")} {
    font-size: 15px;
  }
`;
