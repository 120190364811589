import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Link } from "react-router-dom";
import { Colors as colors,Dark } from "../../utils/Colors";
export const NavbarContainer = styled.nav`
  width: 100%;
  height: ${(props) => (props.extendNavbar ? "40vh" : "100px")};
  background-color: ${(props) => (props.theme==true ? Dark.NavBackground : colors.NavBackground)};
  display: flex;
  box-shadow: 0px 1px 5px #000000;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  transition: top 0.3s;
  position: fixed;
  top: ${(props) => (props.visible ? "0" : "-150px")}; /* Conditional top position */
  z-index: 999; /* Ensure navbar stays on top of other content */
  ${media("medium")} {
    padding-top: ${(props) =>
      props.dropdown ? "40%" : props.extendNavbar ? "20%" : "0"};
    background-color: ${(props) => (props.theme==true ? Dark.NavBackground : colors.NavBackground)};
    width: 100%;
  }
  ${media("small")} {
    padding-top: ${(props) =>
      props.dropdown ? "40%" : props.extendNavbar ? "20%" : "0"};
    background-color: ${(props) => (props.theme==true ? Dark.NavBackground : colors.NavBackground)};
    width: 100%;
  }
`;



export const StyledLink = styled(Link)`
  text-decoration: none;
  color:${(props)=>props.theme==true? Dark.NavLinkColor: colors.NavLinkColor}
`;
export const LeftContainer = styled.div`
  flex: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
  font-family: "Akkurat";
  font-weight: bold;
  font-size: 24px;
`;

export const RightContainer = styled.div`
  flex: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5%;
  font-family: "Akkurat";
  font-weight: bold;
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 100px;
  align-items: center;
  background-color:  ${(props) => (props.theme==true ? Dark.NavBackground : colors.NavBackground)};
  display: flex;
  position:${(props) => (props.extendNavbar==true ? "fixed": "")}; //none
  top:${(props) => (props.extendNavbar==true ? 0: "")};//none
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const NavbarExtendedContainer = styled.div`
  display: none;
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    background:${(props) => (props.theme==true ? Dark.NavExtendedColor : colors.NavExtendedColor)};
    padding:50px;
    position:relative;//none
    z-index:-1;//none
    top:15%;//none
  }
`;

export const NavbarLink = styled(Link)`
  color: ${(props) => (props.theme==true ? Dark.NavLinkColor : colors.NavLinkColor)};
  font-family: "Akkurat";
  text-decoration: none;
  font-size: 20px;
  padding: 20px;
  margin: 10px;
  font-weight: 5px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -4px; /* Adjust to decrease distance */
    left: 0;
    width: 0;
    height: 2px;
    background-color:  ${(props) => (props.theme==true ? Dark.NavLinkColor : colors.NavLinkColor)};
    transition: width 0.3s ease;
    transform-origin: top left; /* Set transform origin */
  }
  &:hover:after {
    width: 100%;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled(Link)`
  color:  ${(props) => (props.theme==true ? Dark.NavLinkColor : colors.NavLinkColor)};
  align-items: right;
  padding: 10px;
  font-size: 20px;
  font-family: "Akkurat";
  text-decoration: none;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: -4px; /* Adjust to decrease distance */
    left: 0;
    width: 0;
    height: 2px;
    background-color: ${(props) => (props.theme==true ? Dark.NavLinkColor : colors.NavLinkColor)};
    transition: width 0.3s ease;
    transform-origin: top left; /* Set transform origin */
  }
  &:hover:after {
    width: 100%;
  }
`;

export const Logo = styled.img`
  max-width: 80px;
  height: auto;
  border-radius:50%;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 80%;
  left: 45%;
  padding: 20px;
  flex-direction: column;
  background-color:  ${(props) => (props.theme==true ? Dark.DropdownHover : colors.NavBackground)};
  box-shadow: ${(props) => (props.theme==true ? '0px 0px 9px rgba(255, 255, 255, 0.1)' : '0px 4px 8px rgba(0, 0, 0, 0.1)')};
  z-index: 1;
  border-radius: 10px;
  display: ${(props) => (props.visible ? "flex":"none")};
`;
export const DropdownExtendedContainer = styled.div`
  position: absolute;
  top: 0%;
  left: 60%;
  display: ${(props) => (props.visible ? "flex":"none")};
  padding: 20px;
  flex-direction: column;
  background-color:  ${(props) => (props.theme==true ? Dark.NavExtendedColor : colors.NavExtendedColor)};
  font-weight:bold;
  box-shadow: ${(props) => (props.theme==true ? '0px 0px 9px rgba(255, 255, 255, 0.1)' : '0px 4px 8px rgba(0, 0, 0, 0.1)')};
  z-index: 1;
  border-radius: 10px;
  ${media('medium')}{
    position:relative;
    left:0%;
  }
`;
export const OpenLinksButton = styled.button`
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  color:  ${(props) => (props.theme==true ? Dark.NavLinkColor : colors.NavLinkColor)};
  font-size: 45px;
  cursor: pointer;
  padding-right:30px;

  @media (min-width: 850px) {
    display: none;
  }
`;
export const DropdownItem = styled(Link)`
  padding: 10px;
  color:  ${(props) => (props.theme==true ? Dark.NavLinkColor : colors.NavLinkColor)};
  font-family:"Akkurat";
  text-decoration: none;
  &:hover {
    background-color: ${(props) => (props.theme==true ? "lightgray" : colors.DropdownHover)};
    color:${(props) => (props.theme==true ? "black" : colors.NavLinkColor)};
  }
`;
export const ContactButton = styled(Link)`
background: ${(props) => (props.theme==true ? Dark.ContactButtonColor : colors.ContactButtonColor)};
color:  ${(props) => (props.theme==true ? Dark.ButtonText : colors.ButtonText)};
font-family: "Akkurat";
text-decoration:none;
font-size: 20px;
text-align:center;
width:250px;
font-weight: bold;
border-radius: 45px;
padding:20px;
transition:0.2s ease-in;
  &:hover {
    background: ${(props) => (props.theme==true ? Dark.ButtonHover : colors.ButtonHover)};
    color:${(props)=> props.theme==true?Dark.HoverButtonText:colors.ButtonText};
  }
  @media (max-width: 850px) {
    display: none;
  }
`;
export const ContactExtendedButton = styled(Link)`
  background: ${(props) => (props.theme==true ? Dark.ContactButtonColor : colors.ContactButtonColor)};
  color: ${(props) => (props.theme==true ? Dark.ButtonText : colors.ButtonText)};
  font-family: "Akkurat";
  text-decoration:none;
  font-size: 20px;
  text-align:center;
  font-weight: bold;
  border-radius: 55px;
  margin-top:20px;
  padding:10px;
  width:150px;
  &:hover {
    background:  ${(props) => (props.theme==true ? Dark.ButtonHover : colors.ButtonHover)};
    color:${(props)=> props.theme==true?Dark.HoverButtonText:colors.ButtonText};
  }
`;
