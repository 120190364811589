import styled from "styled-components";
import { media } from "../../Styles/utils";
import back from "../../Assets/Images/mediumback.jpg";
import dark from "../../Assets/Images/BelowSLiderDark.jpg";
import {Dark, Colors as colors } from "../../utils/Colors";
export const BelowSlider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 0px;
  padding: 10px;
  position:relative;
  background:${(props)=>props.theme==true? Dark.BelowSliderColor:"transparent"};
  justify-content: center;
  ${media("medium")} {
    padding: 0px;
  }
  ${media("small")} {
    padding: 0px;
  }
`;
export const BelowSliderImage = styled.div`
z-index:0;
  ${media("medium")} {
    display: none;
  }
  ${media("small")} {
    display: none;
  }
`;
export const BelowSliderText = styled.div`
  padding: 0px;
`;
export const BelowBack = styled.img`
  position: relative;
  z-index: -1;
  filter: hue-rotate(10deg);
  margin-left: -15%;
  margin-right: -30%;
  ${media("medium")} {
    display: none;
  }
  ${media("small")} {
    display: none;
  }
`;
export const BelowBlogs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 0px;
  background:${(props)=>props.theme==true? Dark.BelowSliderColor:"transparent"};
  margin-top:0px;
  padding: 30px;
  justify-content: center;
  ${media("medium")} {
    padding: 0px;
    background-image: ${(props)=>props.theme==true? `url(${dark})`:`url(${back})`};
    background-size: cover;
    margin-top:0px;
  }
  ${media("small")} {
    padding: 0px;
    background-image:${(props)=>props.theme==true? `url(${dark})`:`url(${back})`};;
    background-size: cover;
    margin-top:0px;
  }
`;
export const BelowBlogText=styled.div`
width:620px;
height:120px;
position:absolute;
margin-left:50%;
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-evenly;
margin-top:35%;
background:${(props)=>props.theme==true? Dark.BelowBlogTextcolor:colors.BelowBlogTextcolor};
${media("medium")} {
  display: none;
}
${media("small")} {
  display: none;
}
`;
export const BelowText=styled.span`
font-family:'Outfit';
font-weight:bold;
color:${(props)=>props.theme==true? Dark.BelowTextCOlor: colors.BelowTextCOlor};
font-size:25px;
text-align:left;
`;