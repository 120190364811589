import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Link } from "react-router-dom";
import { Colors as colors, Dark } from "../../utils/Colors";
export const HeroContainer = styled.div`
  width: 100%;
  height: 100%; /* Set the height to 100% of the viewport height */
  overflow: hidden;
  padding-top:100px;
  background:url(${(props)=>props.background}) no-repeat;
  background-size:cover;
  `;
export const HeroTextWrapper = styled.div`
  box-sizing: content-box;
  background-color:${(props)=>props.theme==true? Dark.HeroTextWrapperColor:colors.HeroTextWrapperColor};
  width:50%;
  padding-left:13%;
  padding-bottom:50px;
  text-align:left;
  background:transparent;
  border-radius: 8px;
  ${media('medium')}{
    height:80%;
    width:80%;
    padding-left:20px;
  }
  ${media('small')}{
    height:100%;
    width:90%;
    padding:20px;
    align-items:center;
  }
`;
export const HeroTextP=styled.p`
font-size:85px;
font-family:'Outfit';
color:${(props)=>props.theme==true? Dark.HeroMainText:colors.HeroMainText};
font-weight:bold;
${media('medium')}{
    font-size:60px;
}
${media('small')}{
    font-size:40px;
}
`;
export const HeroMainButton=styled(Link)`
background: ${(props)=>props.theme==true? Dark.ContactButtonColor:colors.ContactButtonColor};
color: ${(props)=>props.theme==true? Dark.ButtonText:colors.ButtonText};
font-family: "Outfit";
text-decoration:none;
font-size: 20px;
text-align:center;
font-weight: bold;
border-radius: 15px;
padding:20px;
  &:hover {
    background:${(props)=>props.theme==true? Dark.ButtonHover:colors.ButtonHover};
    color:${(props)=>props.theme==true? Dark.HoverButtonText:colors.HeroMainText};
  }
  ${media("medium")} {
    padding:15px;
  }
  ${media("small")} {
    padding:10px;
    font-size:15px;
  }


`;
export const HeroButton = styled.a`
background:${(props)=> props.theme==true?Dark.ContactButtonColor:colors.ContactButtonColor};
color: ${(props)=> props.theme==true?Dark.ButtonText:colors.ButtonText};;
font-family: "Outfit";
text-decoration:none;
font-size: 20px;
text-align:center;
cursor:pointer;
font-weight: bold;
border-radius: 15px;
padding:20px;
transition: 0.2s ease-in;
  &:hover {
    background:${(props)=> props.theme==true?Dark.ButtonHover:colors.ButtonHover};
    color:${(props)=> props.theme==true?Dark.HoverButtonText:colors.ButtonText};
  }
  ${media("medium")} {
    padding:15px;
  }
  ${media("small")} {
    padding:10px;
    font-size:15px;
  }
`;