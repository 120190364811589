import React, { useState, useRef, useEffect } from 'react';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useDarkMode } from "../DarkModeContext";
import { useSpring, animated } from 'react-spring';
import {
    HeroContainer,
    HeroTextP,
    HeroTextWrapper,
    HeroMainButton,
} from "./styles/Hero.styles";
import { HeroSub } from "../components/Hero/hero.styles";
import { Words } from "../components/RotatingText/RotatingText.styles";
import background from "../Assets/Images/qaback.jpg";
import { useParams } from 'react-router';
import casestudies from "./CaseStudyData.json";
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeroButton } from './styles/Hero.styles';
import { TestimonialCard, TestimonialContainer, TestimonialImage, TestimonialName, TestimonialText, Testimonialtextcontainer } from '../components/Testimonials/Testimonial.styles';
import { BlogCard, BlogHeading, BlogImage, BlogInner, BlogSub, BlogText, BlogsContainer, BlogsHeading, BlogsSub } from '../components/Blogs/blogs.styles';
import { AboutSection } from '../components/About/About.styles';
function CaseStudyPage() {
    const { isDarkMode } = useDarkMode();
    const [isVisibleFooter, setIsVisibleFooter] = useState(false);
    const [isVisibleAbout, setIsVisibleAbout] = useState(false);
    const [isVisibleProb, setIsVisibleProb] = useState(false);
    const [isVisibleSol, setIsVisibleSol] = useState(false);
    const [isVisibleHero, setIsVisibleHero] = useState(true);
    const { blogheadline } = useParams();
    const refFooter = useRef();
    const refHero = useRef();
    const refAbout = useRef();
    const refProb = useRef();
    const refSol = useRef();

    const animationFooter = useSpring({
        opacity: isVisibleFooter ? 1 : 0,
        transform: isVisibleFooter ? 'translateY(0)' : 'translateY(70px)',
    });
    const animationAbout = useSpring({
        opacity: isVisibleAbout ? 1 : 0,
        transform: isVisibleAbout ? 'translateY(0)' : 'translateY(70px)',
    });
    const animationProb = useSpring({
        opacity: isVisibleProb ? 1 : 0,
        transform: isVisibleProb ? 'translateY(0)' : 'translateY(70px)',
    });
    const animationSol = useSpring({
        opacity: isVisibleSol ? 1 : 0,
        transform: isVisibleSol ? 'translateY(0)' : 'translateY(70px)',
    });
    const animationHero = useSpring({
        opacity: isVisibleHero ? 1 : 0,
        transform: isVisibleHero ? 'translateY(0)' : 'translateY(80px)',
    });
    useEffect(() => {
        const style = document.createElement("style");
        style.textContent = `
          @media (min-width: 768px) {
            .TestimonialCont{
              flex-direction:row;
              align-items:center;
            }
            .blogcard{
                height:450px;
            }
          }
          @media (max-width: 767px) {
            .TestimonialCont{
              flex-direction:column;
              gap:5px;
              align-items:center;
            }
            .blogcard{
                height:100%;
            }
          }
        `;
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const isMediumOrSmall = window.innerWidth <= 1024;
            setIsVisibleAbout(isMediumOrSmall);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleScroll = () => {
        if (refFooter.current) {
            const topFooter = refFooter.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            setIsVisibleFooter(topFooter < windowHeight * 0.8);
        }
        if (refAbout.current) {
            const topAbout = refAbout.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            setIsVisibleAbout(topAbout < windowHeight * 0.8);
        }
        if (refProb.current) {
            const topProb = refProb.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            setIsVisibleProb(topProb < windowHeight * 0.8);
        }
        if (refSol.current) {
            const topSol = refSol.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            setIsVisibleSol(topSol < windowHeight * 0.8);
        }
    };
    const handleVisitClick = (e) => {
        window.open(casestudyd.link, '_blank');
    };
    
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const casestudyd = casestudies[blogheadline];

    // Conditionally set the width based on screen size
    const heroTextWrapperStyle = {
        width: window.innerWidth <= 1024 ? '100%' : '60%',
    };

    return (
        <>
            <Header />
            <animated.div style={animationHero} ref={refHero}>
                <HeroContainer
                    background={casestudyd.background}
                    style={{ backgroundPosition: "left" }}
                    theme={true}
                >
                    <HeroTextWrapper theme={true} style={heroTextWrapperStyle}>
                        <HeroTextP theme={true}>{blogheadline}</HeroTextP>
                        <Words theme={true}></Words>
                        <HeroSub style={{ color: "white" }} theme={true}>
                            {casestudyd.description}
                        </HeroSub>
                        <HeroMainButton theme={true} to="/Aboutus">Connect With Us</HeroMainButton>
                    </HeroTextWrapper>
                </HeroContainer>
            </animated.div>
            <animated.div style={animationAbout} ref={refAbout}>
                <BlogsContainer theme={true} className='TestimonialCont' style={{ height: "100%" }}>
                    <BlogsHeading style={{ width: window.innerWidth >= 868 ? "40%" : "100%" }} theme={true}>About our Client{window.innerWidth >= 868 && <TestimonialImage theme={true} style={{ height: "250px" }}>
                        <img src={casestudyd.ClientImage} style={{
                            height: "100%",
                            width: "100%"
                        }} />
                    </TestimonialImage>}</BlogsHeading>
                    <BlogInner theme={true}  >
                        <BlogCard className='blogcard' theme={true} style={{ width: window.innerWidth >= 868 ? "850px" : "100%", flexDirection: window.innerWidth >= 868 ? "row" : "column" }}>
                            {window.innerWidth < 868 && <TestimonialImage theme={true}>
                                <img src={casestudyd.ClientImage} style={{
                                    width: "100%",
                                }} />
                            </TestimonialImage>}
                            <BlogText theme={true} style={{ width: "90%" }}>
                                <BlogHeading>
                                    <FontAwesomeIcon icon={faQuoteLeft} /><br /> {casestudyd.clientName}
                                </BlogHeading>
                                <br></br>
                                <TestimonialText>{casestudyd.AboutClient}</TestimonialText>
                            </BlogText>
                        </BlogCard>
                    </BlogInner>
                </BlogsContainer>
            </animated.div>
            <animated.div style={animationProb} ref={refProb}>
                <BlogsContainer theme={true} className='TestimonialCont' style={{ height: "100%" }}>
                    {window.innerWidth < 868 && <BlogsHeading style={{ width: "100%" }} theme={true}>The Problem{window.innerWidth >= 868 && <TestimonialImage theme={true} style={{ height: "250px" }}>
                        <img src={casestudyd.ProblemImage} style={{
                            height: "100%",
                            width: "100%"
                        }} />
                    </TestimonialImage>}</BlogsHeading>}
                    <BlogInner theme={true}  >
                        <BlogCard className='blogcard' theme={true} style={{ width: window.innerWidth >= 868 ? "850px" : "100%", flexDirection: window.innerWidth >= 868 ? "row" : "column" }}>
                            {window.innerWidth < 868 && <TestimonialImage theme={true}>
                                <img src={casestudyd.ProblemImage} style={{
                                    width: "100%",
                                }} />
                            </TestimonialImage>}
                            <BlogText theme={true} style={{ width: "90%" }}>
                                <BlogHeading>
                                    <FontAwesomeIcon icon={faQuoteLeft} /><br /> {casestudyd.ProblemStatement}
                                </BlogHeading>
                                <br></br>
                                <TestimonialText>{casestudyd.ProblemDescription}</TestimonialText>
                            </BlogText>
                        </BlogCard>
                    </BlogInner>
                    {window.innerWidth >= 868 && <BlogsHeading style={{ width: "40%" }} theme={true}>The Problem{window.innerWidth >= 868 && <TestimonialImage theme={true} style={{ height: "250px" }}>
                        <img src={casestudyd.ProblemImage} style={{
                            height: "100%",
                            width: "100%"
                        }} />
                    </TestimonialImage>}</BlogsHeading>}
                </BlogsContainer>
            </animated.div>
            <animated.div style={animationSol} ref={refSol}>
                <BlogsContainer theme={true} className='TestimonialCont' style={{ height: "100%" }}>
                    <BlogsHeading style={{ width: window.innerWidth >= 868 ? "40%" : "100%" }} theme={true}>Our Approach{window.innerWidth >= 868 && <TestimonialImage theme={true} style={{ height: "250px" }}>
                        <img src={casestudyd.SolImage} style={{
                            height: "100%",
                            width: "100%"
                        }} />
                    </TestimonialImage>}</BlogsHeading>
                    <BlogInner theme={true}  >
                        <BlogCard className='blogcard' theme={true} style={{ width: window.innerWidth >= 868 ? "850px" : "100%", flexDirection: window.innerWidth >= 868 ? "row" : "column" }}>
                            {window.innerWidth < 868 && <TestimonialImage theme={true}>
                                <img src={casestudyd.SolImage} style={{
                                    width: "100%",
                                }} />
                            </TestimonialImage>}
                            <BlogText theme={true} style={{ width: "90%" }}>
                                <BlogHeading>
                                    <FontAwesomeIcon icon={faQuoteLeft} /><br /> {casestudyd.SolutionHead}
                                </BlogHeading>
                                <br></br>
                                <TestimonialText>{casestudyd.Solution}</TestimonialText>
                            </BlogText>
                        </BlogCard>
                    </BlogInner>
                </BlogsContainer>
            </animated.div>
            {/* <AboutSection>
                <a href='#' onClick={handleVisitClick}>Visit our Solution</a>
            </AboutSection> */}
            <animated.div style={animationFooter} ref={refFooter}>
                <Footer theme={isDarkMode} />
            </animated.div>
        </>
    )
}

export default CaseStudyPage;
