import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Link } from "react-router-dom";
import { Colors as colors } from "../../utils/Colors";
export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 50px;
  overflow:hidden;
  justify-content: space-evenly;
  justify-items: center;
  bottom: 0;
  background: ${colors.FooterContainerBackground};

  ${media("huge")} {
    padding: 100px;
  }
  ${media("large")} {
    padding: 10px ;

  }
  ${media("medium")} {
    flex-direction:column;
    justify-content:center;
    align-items:Center;
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 35%;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Outfit';
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: ${colors.Text};
  width: 100px;
  border-right: 1px solid ${colors.FooterBorder};

  ${media("medium")} {
    align-items:center;
    border-top: 1px solid ${colors.FooterBorder};
    border-right: none;
    width:100%;
  }
`;


export const SocialLinksContainer = styled.div`
  flex: 0 0 20%;
  a {
    position: relative;
    color: ${colors.Text};
    text-decoration: none;
    &:hover {
      color: ${colors.Text};
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      bottom: -4px; /* Adjust this value to increase or decrease the distance */
      left: 0;
      background-color: ${colors.Text};
      transform: scaleX(0);
      transform-origin: top left;
      transition: transform 0.3s ease;
    }
    &:hover::before {
      transform: scaleX(1);
    }
  }
  border-right: 1px solid ${colors.FooterBorder};

  ${media("medium")} {
    border-top: 1px solid ${colors.FooterBorder};
    border-right: none;
    width:100%;
  }
`;

export const SocialList = styled.ul`
  list-style: none;
  text-align: left;
  line-height: 1.8;
  font-size: 16px;

  h3 {
    margin-bottom: 10px;
  }
`;

export const SocialA = styled(Link)`
  color: ${colors.Text};
  text-decoration: none;
`;

export const SocialLinkedin = styled.a`
color: ${colors.Text};
  text-decoration: none;
`;
export const Social = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

export const CopyRightContainer = styled.div`
  p {
    color: ${colors.Text};
    font-size: 1rem;
    font-weight: bold;
    line-height: 30px;
    text-align: center;

    ${media("medium")} {
      font-size: 1.5rem;
      text-align: center;
    }
    ${media("small")} {
      font-size: 1rem;
      text-align: center;
    }
  }
`;
