import React from "react";
import {
  FooterContainer,
  FooterLogo,
  CopyRightContainer,
  SocialLinksContainer,
  Social,
  SocialList,
  SocialA,
  SocialLinkedin,
} from "./footer.styles";
import LogoImage from "../../Assets/Images/Logo.png";
import YoutubeImage from "../../Assets/Images/youtube.svg";
import TwitterImage from "../../Assets/Images/twitter.svg";
import InstaImage from "../../Assets/Images/instagram.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
function Footer() {
  return (
    <>
      <FooterContainer>
        <FooterLogo>
          <img src={LogoImage} width={120} alt="logo" />
          <p>
           BASETECH
          </p>
        </FooterLogo>
        <SocialLinksContainer>
          <SocialList>
            <h3 style={{fontSize:"28px"}}>Services</h3>
            <li>
              <SocialA to="/Ai">Artificial Intelligence</SocialA>
            </li>
            {/* <li>
              <SocialA to="/DevOps">DevOps</SocialA>
            </li> */}
            <li>
              <SocialA to="/WebDev">Web Development</SocialA>
            </li>
            <li>
              <SocialA to="/Qa">Quality Assurance</SocialA>
            </li>
          </SocialList>
        </SocialLinksContainer>
        <SocialLinksContainer>
          <SocialList>
            <h3 style={{fontSize:"28px"}}>About Us</h3>
            <li>
            <SocialA to="/Aboutus">About</SocialA>
            </li>
            <li>
            <SocialA to="/ContactUs">Contact Us</SocialA>
            </li>
            <li>
            <SocialA to="/privacy-policy">Privacy Policy</SocialA>
            </li>
          </SocialList>
        </SocialLinksContainer>
        <SocialLinksContainer>
          <SocialList>
            <h3 style={{fontSize:"28px"}}>Connect with us</h3>
            <li>
            <SocialLinkedin href="https://www.linkedin.com/company/basetechpartners" target="_blank">LinkedIn <FontAwesomeIcon icon={faLinkedin} /></SocialLinkedin>

            </li>
            <li>
              <SocialLinkedin href="mailto:contact@basetech.in" target="_top">
              contact@basetech.in <FontAwesomeIcon icon={faEnvelope} />
              </SocialLinkedin>
            </li>
            {/* <li>
              <br />
              <Social>
                <SocialA
                  to="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={YoutubeImage}
                    height={25}
                    width={25}
                    alt="facebook"
                  />
                </SocialA>
                <SocialA
                  to="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={TwitterImage}
                    height={25}
                    width={25}
                    alt="twitter"
                    style={{fill:'black'}}
                  />
                </SocialA>
                <SocialA
                  to="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={InstaImage}
                    height={25}
                    width={25}
                    alt="instagram"
                  />
                </SocialA>
              </Social>
            </li> */}
          </SocialList>
        </SocialLinksContainer>
      </FooterContainer>
      <CopyRightContainer>
        <p>2024 © All rights reserved</p>
      </CopyRightContainer>
    </>
  );
}

export default Footer;
