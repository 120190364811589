import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Dark, Colors as colors } from "../../utils/Colors"; // Importing colors from Colors.js
import ReactPhoneInput from 'react-phone-input-2';

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
  background: linear-gradient(to top, #004ff9, #000000);
  color: ${colors.ContactContainerColor}; // Using ContactContainerColor for text color
`;

export const ContactImage = styled.img`
  ${media("medium")} {
    display: none;
  }
  ${media("small")} {
    display: none;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ContactFormInput = styled.input`
  width: 100%; /* Added width property */
  background: ${colors.ContactFormInputBackground}; 
  border: 1px solid ${colors.ContactFormInputBorder}; 
  border-radius: 20px;
  color: ${colors.ContactFormInputColor}; 
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;

  &:focus {
    background: rgba(255, 255, 255, 1);
    color: ${colors.ContactFormButtonHoverBackground};
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.ContactFormInputPlaceholder}; 
  }

  &:focus::placeholder {
    color:${colors.ContactFormButtonHoverBackground};
    opacity: 0.8;
  }
`;


export const ContactFormTextarea = styled.textarea`
  width: 100%;
  background: ${colors.ContactFormInputBackground}; // Using ContactFormInputBackground for background
  border: 1px solid ${colors.ContactFormInputBorder}; // Using ContactFormInputBorder for border
  border-radius: 20px;
  color: ${colors.ContactFormInputColor}; // Using ContactFormInputColor for text color
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;

  &:focus {
    background: ${colors.ContactFormTextAreaFocus};
    color: ${colors.ContactFormButtonHoverBackground};
  }

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.ContactFormInputPlaceholder}; // Using ContactFormInputPlaceholder for placeholder color
  }

  &:focus::placeholder {
    color: ${colors.ContactFormButtonHoverBackground};
    opacity: 0.8;
  }
`;

export const ContactFormButton = styled.button`
  font-weight: 700;
  color: ${props => props.disabled ? colors.ContactFormButtonColorDisabled : colors.ContactFormButtonColor}; // Using ContactFormButtonColor for text color
  background-color: ${props => props.disabled ? colors.LightGray : colors.ContactFormButtonBackground}; // Using ContactFormButtonBackground for background color
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;

  span {
    z-index: 1;
    position: relative;
  }

  &:hover {
    color: ${props => props.disabled ? colors.ContactFormButtonColorDisabled : colors.ContactFormButtonHoverColor}; // Using ContactFormButtonHoverColor for text color on hover
  }

  &::before {
    content: "";
    background: ${props => props.disabled ? colors.LightGray : colors.ContactFormButtonHoverBackground}; // Using ContactFormButtonHoverBackground for background color on hover
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
  }

  &:hover::before {
    width: ${props => props.disabled ? '0' : '100%'};
  }
`;


export const SuccessMessage = styled.span`
  color: ${colors.SuccessMessage};
  font-family: 'Outfit';
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  padding: 10px 15px;
  background-color: white;
  border-radius: 15px;
`;

export const DangerMessage = styled.span`
  color: ${colors.DangerMessage};
  font-family: 'Outfit';
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  padding: 10px 15px;
  background-color: white;
  border-radius: 15px;
`;

export const StyledPhoneInputWrapper = styled.div`
  width: calc(100% + 50px);
  height: 100%; /* Added height property */
  margin: 0 0 8px 0;
  .react-tel-input input {
    width:100%;
    height:100%;
    background: ${colors.ContactFormInputBackground}; 
    border: 1px solid ${colors.ContactFormInputBorder}; 
    border-radius: 20px;
    color: ${colors.ContactFormInputColor}; 
    padding: 18px 48px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #f5f5f5;
    border: 1px solid #cacaca;
    color:black;
    border-radius: 15px 0 0 15px;
}
.react-tel-input .flag-dropdown.open .selected-flag, 
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    border-radius: 15px 0 0 15px;
}
  .react-tel-input input:focus {
    background: rgba(255, 255, 255, 1);
    color: ${colors.ContactFormButtonHoverBackground};
  }

  .react-tel-input input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.ContactFormInputPlaceholder}; // Using ContactFormInputPlaceholder for placeholder color
  }

  .react-tel-input input:focus::placeholder {
    color:${colors.ContactFormButtonHoverBackground};
    opacity: 0.8;
  }
`;

export const StyledPhoneInput = ({ ...rest }) => (
  <StyledPhoneInputWrapper>
    <ReactPhoneInput {...rest} />
  </StyledPhoneInputWrapper>
);