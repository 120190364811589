import styled from "styled-components";
import { media } from "../../Styles/utils";
import { Colors as colors } from "../../utils/Colors"; // Corrected import

export const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.TestimonialBackground}; // Interpolated background color
  align-items: center;
  padding: 50px;
  ${media("medium")} {
    flex-direction: column;
    padding: 30px;
    height: 100%;
  }
  ${media("small")} {
    height: 100%;
    padding: 30px;
    flex-direction: column;
  }
`;

export const TestimonialCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; // Moved align-items here
  ${media("medium")} {
    padding: 10px;
    width: 100%;
  }
  ${media("small")} {
    padding: 10px;
    width: 100%;
  }
`;

export const TestimonialImage = styled.div`
  align-items: center;
  padding: 30px;
  ${media("medium")} {
    padding: 10px;
  }
  ${media("small")} {
    padding: 10px;
  }
`;

export const Testimonialtextcontainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px; /* Adjust padding as needed */
`;

export const TestimonialText = styled.span`
  font-family: 'Outfit';
  font-weight: normal;
  color: ${colors.Text};
  font-size: 20px;
`;

export const TestimonialName = styled.span`
  font-family: 'Outfit';
  font-weight: bold;
  color: ${colors.Text};
  font-size: 15px;
`;
