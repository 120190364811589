import React from "react";
import RotatingText from "../RotatingText/RotatingText";
import { HeroBack, HeroBackDots, HeroContainer } from "./hero.styles";
import heroback from "../../Assets/Images/heroback.jpg";
import herobackdark from "../../Assets/Images/herobackdarkimage.jpg";
import herobackdots from "../../Assets/Images/herobackdots.png";
import { useDarkMode } from "../../DarkModeContext";
function Hero() {
  const {isDarkMode}= useDarkMode();
  return (
    <HeroContainer theme={isDarkMode}>
      <HeroBack src={isDarkMode? "":heroback} theme={isDarkMode}/>
      <RotatingText theme={isDarkMode}/>
      <HeroBackDots src={herobackdots} theme={isDarkMode}/>
    </HeroContainer>
  );
}

export default Hero;
