import styled from "styled-components";
import { media } from "../../Styles/utils";
import {Colors as colors, Dark} from "../../utils/Colors";
export const SliderContainer = styled.div`
  width: 100%;
  overflow: hidden;
  background:${(props)=> props.theme==true? Dark.AICaseColor:"white"};
  padding-top:50px;
  padding-bottom:50px;
  ${media("medium")} {
    padding-top:15px;
    padding-bottom:15px;
    width:100%;
    overflow:hidden;
  }
  ${media("small")} {
    padding-top:15px;
    padding-bottom:15px;
    width:100%;
    overflow:hidden;
  }
`;

export const SliderCard=styled.div`
display: flex;
  flex-direction: column;
  gap: 10px;
  align-items:center;
  background-color:white;
  min-height:580px;
  margin-left:-18px;
  border-radius:25px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  justify-content:space-evenly;
  padding:15px;
  ${media("medium")} {
    min-height:980px;
  margin-left:-15px;
  align-items:center;
  }
  ${media("small")} {
    min-height:980px;
  margin-left:-15px;
  align-items:center;
  justify-content:flex-start;
  }
`;

export const SliderImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

// export const SliderImage = styled.img`
//   height: 90px;
//   max-width: 100%;
// `;

export const SliderImageCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  width: 80%;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) { /* Adjust as needed */
    justify-content: space-between;
  }

  @media (min-width: 992px) { /* Adjust as needed */
    justify-content: flex-start;
  }
`;

export const SliderImage = styled.img`
  height: 100px;
  min-width: 100px;
  border-radius: 15px;
  border: 2px solid transparent; /* Set initial border as transparent */
  transition: border 1.0s ease; /* Transition only the border property */

  /* Hover effect with border change */
  &:hover {
    border-color: #2596be; /* Change the border color on hover */
  }

  /* Hover effect with box shadow */
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`;
