import React, { useState } from "react";
import { Col } from "react-bootstrap";
import {
  ContactContainer,
  ContactFormButton,
  ContactFormInput,
  ContactFormTextarea,
  ContactImage,
  SuccessMessage,
  DangerMessage,
  ContactInfo,
  PhInp,
  StyledPhoneInput,
} from "./Contact.styles";
import { BlogsHeading, BlogsSub } from "../Blogs/blogs.styles";
import contactback from "../../Assets/Images/contact-img.svg";
import { Tooltip } from "react-tooltip";
import 'react-phone-input-2/lib/style.css';

function Contact() {
  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "+91",
    message: "",
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState("Send");
  const [status, setStatus] = useState({});
  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    message: false,
  });

  const validateEmail = (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    return isValid;
  };

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
    setTouchedFields({
      ...touchedFields,
      [category]: true,
    });
  };
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const isFormValid = () => {
    const isValid =
      Object.values(formDetails).every((value) => value.trim() !== "") &&
      validateEmail(formDetails.email) &&
      formDetails.message.split(/\s+/).length >= 20;
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      setStatus({
        success: false,
        message: "Please fill in all fields",
      });
      return;
    }

    setButtonText("Sending...");

    // Prepare payload
    const payload = {
      first_name: formDetails.firstName,
      last_name: formDetails.lastName,
      email_address: formDetails.email,
      phone_number: formDetails.phone,
      message: formDetails.message,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL+"submit-form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        setStatus({
          success: true,
          message: responseData.message,
        });
        // Reset form details
        setFormDetails(formInitialDetails);
        setTouchedFields({
          firstName: false,
          lastName: false,
          email: false,
          phone: false,
          message: false,
        });
      } else {
        setStatus({
          success: false,
          message: "Failed to submit form",
        });
      }
    } catch (error) {
      setStatus({
        success: false,
        message: "An error occurred while submitting the form",
      });
    } finally {
      setButtonText("Send");
    }
  };

  return (
    <ContactContainer>
      <ContactImage src={contactback} />
      <ContactInfo>
        <BlogsSub style={{ color: "white" }}>
          Say Something to start the chat
        </BlogsSub>
        <BlogsHeading style={{ color: "white", textAlign: "left" }}>
          Contact Information
        </BlogsHeading>
        <form
          onSubmit={handleSubmit}
          style={{ width: "70%", justifyContent: "space-between" }}
        >
          <ContactFormInput
            type="text"
            value={formDetails.firstName}
            placeholder="First Name"
            onChange={(e) => onFormUpdate("firstName", e.target.value)}
            required
          />
          {touchedFields.firstName && !formDetails.firstName.trim() && (
            <small style={{ color: "red", fontSize: "15px", textAlign: "right" }}>Please enter your first name</small>
          )}
          <ContactFormInput
            type="text"
            value={formDetails.lastName}
            placeholder="Last Name"
            onChange={(e) => onFormUpdate("lastName", e.target.value)}
            required
          />
          {touchedFields.lastName && !formDetails.lastName.trim() && (
            <small style={{ color: "red", fontSize: "15px", textAlign: "right" }}>Please enter your last name</small>
          )}
          <ContactFormInput
            type="email"
            value={formDetails.email}
            placeholder="Email Address"
            onChange={(e) => onFormUpdate("email", e.target.value)}
            required
          />
          {touchedFields.email && !validateEmail(formDetails.email) && (
            <small style={{ color: "red", fontSize: "15px", textAlign: "right" }}>Please enter a valid email address</small>
          )}
          {/* <ContactFormInput
            type="tel"
            value={formDetails.phone}
            placeholder="Phone No."
            onChange={(e) => onFormUpdate("phone", e.target.value)}
            required
          />
          {touchedFields.phone && !validatePhone(formDetails.phone) && (
            <small style={{ color: "red", fontSize: "15px", textAlign: "right" }}>Please enter a valid phone number</small>
          )} */}
          <StyledPhoneInput
          country={'in'}
            value={formDetails.phone}
            placeholder="Enter Phone No."
            countryCodeEditable={false}
            onChange={(phone) => onFormUpdate('phone', phone)}
            inputProps={{
              required: true,
            }}
          />

          <ContactFormTextarea
            rows="6"
            value={formDetails.message}
            placeholder="Message"
            minLength={20}
            onChange={(e) => onFormUpdate("message", e.target.value)}
            required
          />
          {touchedFields.message && formDetails.message.split(/\s+/).length < 20 && (
            <small style={{ color: "red", fontSize: "15px", textAlign: "right" }}>Please enter at least 20 words</small>
          )}<br />
          <ContactFormButton type="submit" disabled={!isFormValid()}>
            <span>{buttonText}</span>
          </ContactFormButton><br /><br />
          {status.message && (
            <Col>
              {status.success ? (
                <SuccessMessage>{status.message}</SuccessMessage>
              ) : (
                <DangerMessage>{status.message}</DangerMessage>
              )}
            </Col>
          )}
        </form>
      </ContactInfo>
    </ContactContainer>
  );
}

export default Contact;
