import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { AiCaseStudies } from './styles/Ai.styles';
import DevOpsCaseStudies from "../data/DevOpsCaseStudies.json";
import WebDevCaseStudies from "../data/WebDevCaseStudies.json";
import QaCaseStudies from "../data/QaCaseStudies.json";
import Aicasestudy from "../data/AiCasestudies.json";
import CaseStudy from '../components/CaseStudies';
import { useSpring, animated } from 'react-spring';

function CaseStudies() {
  const [isVisibleAi, setIsVisibleAi] = useState(true); // Set AiCaseStudy to true by default
  const [isVisibleDevOps, setIsVisibleDevOps] = useState(false);
  const [isVisibleWebDev, setIsVisibleWebDev] = useState(false);
  const [isVisibleQa, setIsVisibleQa] = useState(false);

  const refAi = useRef();
  const refDevOps = useRef();
  const refWebDev = useRef();
  const refQa = useRef();

  const animationAi = useSpring({
    opacity: isVisibleAi ? 1 : 0,
    transform: isVisibleAi ? 'translateY(0)' : 'translateY(80px)',
  });

  const animationDevOps = useSpring({
    opacity: isVisibleDevOps ? 1 : 0,
    transform: isVisibleDevOps ? 'translateY(0)' : 'translateY(80px)',
  });

  const animationWebDev = useSpring({
    opacity: isVisibleWebDev ? 1 : 0,
    transform: isVisibleWebDev ? 'translateY(0)' : 'translateY(80px)',
  });

  const animationQa = useSpring({
    opacity: isVisibleQa ? 1 : 0,
    transform: isVisibleQa ? 'translateY(0)' : 'translateY(80px)',
  });

  const handleScroll = () => {
    if (refAi.current) {
      const topAi = refAi.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleAi(topAi < windowHeight * 0.8);
    }
    if (refDevOps.current) {
      const topDevOps = refDevOps.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleDevOps(topDevOps < windowHeight * 0.8);
    }
    if (refWebDev.current) {
      const topWebDev = refWebDev.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleWebDev(topWebDev < windowHeight * 0.8);
    }
    if (refQa.current) {
      const topQa = refQa.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisibleQa(topQa < windowHeight * 0.8);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <Header />
      <AiCaseStudies style={{ backgroundColor: "white" }}>
        <animated.div style={animationAi} ref={refAi}>
          <CaseStudy CaseStudies={Aicasestudy.CaseStudies} blogsheading={"Artificial Intelligence"}  direction="right"/>
        </animated.div>
        <animated.div style={animationDevOps} ref={refDevOps}>
          <CaseStudy CaseStudies={DevOpsCaseStudies.CaseStudies} blogsheading={"DevOps"} direction="top"/>
        </animated.div>
        <animated.div style={animationWebDev} ref={refWebDev}>
          <CaseStudy CaseStudies={WebDevCaseStudies.CaseStudies} blogsheading={"Web Development"} direction="left" />
        </animated.div>
        <animated.div style={animationQa} ref={refQa}>
          <CaseStudy CaseStudies={QaCaseStudies.CaseStudies} blogsheading={"Quality Assurance"} direction="top"/>
        </animated.div>
      </AiCaseStudies>
      <Footer />
    </div>
  )
}

export default CaseStudies;
