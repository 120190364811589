const Aiservices = [
  {
    name: "Computer Vision",
    description: "Optimize your visual content with Basetech's comprehensive image enhancement services. From image creation to background removal, object identification, image categorization, text extraction, and motion tracking, we provide seamless solutions. Trust us to streamline your image processing, boosting your brand's visual impact.",
    image: require("./Images/Ai Services/Computer Vision.png")
  },
  {
    name: "Recommendation Engine",
    description: "Boost user engagement with Basetech's tailored recommendation systems, designed to enhance click-through and conversion rates. Whether you're an established enterprise or a startup without user data, we're here to guide you toward achieving higher returns and customer satisfaction. Let's unlock your business's potential together.",
    image: require("./Images/Ai Services/Recommendation Engine.jpg")
  },
  {
    name: "Natural Language Processing",
    description: "Unlock the full potential of language with Basetech's Natural Language Processing services. Understand, interpret, and respond to human language in a way that elevates your customer experience and streamlines your operations.",
    image: require("./Images/Ai Services/NLP.jpg")
  },
  {
    name: "Audio AI",
    description: "Revolutionize your audio interactions with Basetech's Audio AI solutions. From speech recognition to sound analysis, our technology listens and learns, providing insights that can transform your business communication and data analysis.",
    image: require("./Images/Ai Services/Audio AI.jpg")
  },
  {
    name: "Predictive Analytics",
    description: "Anticipate the future with Basetech's Predictive Analytics. Harness the power of data to make informed decisions, foresee market trends, and stay ahead of the curve in your industry.",
    image:require("./Images/Ai Services/Predictive Analysis.jpg")
  },
  {
    name: "Time Series Forecasting",
    description: "Navigate the flow of time with Basetech's Time Series Forecasting. Predict patterns and trends in your data over time, enabling you to make strategic plans and optimize your business operations with confidence.",
    image: require("./Images/Ai Services/Time Series.jpg")
  },
  {
    name: "Generative AI / LLM Integrations",
    description: "Innovate with Basetech's Generative AI and Large Language Model (LLM) Integrations. Create content, design prototypes, and generate solutions that push the boundaries of creativity and practicality.",
    image: require("./Images/Ai Services/Generative AI.jpg")
  },
  {
    name: "Chatbots / Assistants",
    description: "Enhance customer engagement with Basetech's Chatbots and Assistants. Provide instant support, automate interactions, and personalize your service, ensuring your customers always have a helpful guide by their side.",
    image: require("./Images/Ai Services/ChatBot.jpg")
  },
  {
    name: "MLOps",
    description: "Streamline your machine learning lifecycle with Basetech's MLOps services. Ensure seamless integration, deployment, and maintenance of your ML models, accelerating your path from experimentation to production with efficiency and scalability.",
    image: require("./Images/Ai Services/MLops.jpg")
  },
  {
    name: "AI Consulting",
    description: "Navigate the AI landscape with confidence through Basetech's AI Consulting services. Our experts provide personalized guidance, from strategy to execution, to help you leverage the latest AI technologies and transform your business operations for optimal outcomes.",
    image: require("./Images/Ai Services/Ai Consulting.jpg")
  },
  {
    name: "Data Engineering",
    description: "Empower your data infrastructure with Basetech's Data Engineering services. We build robust, scalable systems that manage and optimize your data flow, ensuring you have a solid foundation for analytics and decision-making.",
    image: require("./Images/Ai Services/Data Engineering.jpg")
  },
  {
    name: "Data Annotations",
    description: "Refine your machine learning models with Basetech's Data Annotation services. We provide precise, high-quality labeling of your data, enhancing the accuracy and performance of your AI applications.",
    image: require("./Images/Ai Services/Data Annotations.png")
  }
];

export default Aiservices;
