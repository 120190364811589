import React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { AboutHeading, AboutSection } from '../components/About/About.styles'
import styled from 'styled-components';
import { media } from "../Styles/utils";
import { privacyPolicy } from '../data/Privacydata';

const PrivacyHead = styled.span`
text-align: left;
  font-family: 'Outfit';
  font-size: 25px;
  font-weight: bold;
  padding: 20px;
  ${media("medium")} {
    font-size: 20px;
  }
  ${media("small")} {
    font-size: 17px;
  }`;

const PrivacyTextSub = styled.span`text-align: left;
font-family: 'Outfit';
font-size: 20px;
font-weight: normal;
padding: 0px 20px;
${media("medium")} {
  font-size: 18px;
}
${media("small")} {
  font-size: 15px;
}`;
function Privacy() {
    return (
        <>
            <Header />
            <AboutSection style={{ paddingTop: "150px", background: "white", alignItems: "start" }}>
                <AboutHeading>Privacy Policy</AboutHeading>
                {privacyPolicy.map((section, index) => (
                    <React.Fragment key={index}>
                        <PrivacyHead>{section.privacyHeading}</PrivacyHead>
                        <PrivacyTextSub dangerouslySetInnerHTML={{ __html: section.privacyDesc.replace(/\n/g, '<br/>') }} />
                    </React.Fragment>
                ))}
            </AboutSection>
            <Footer />
        </>
    )
}

export default Privacy