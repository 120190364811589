import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom"; // Import NavLink
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BlogText,
  BlogHeading,
  BlogCard,
  BlogSub,
  BlogsContainer,
  BlogInner,
} from "./Blogs/blogs.styles";
import { HeroButton } from "./Hero/hero.styles";
import Arrow from "../Assets/Images/arrow.svg"; // Change the path as per your file structure
import ArrowFilled from "../Assets/Images/ArrowBlack.svg"; // Change the path as per your file structure
import { BlogsHeading, BlogsSub } from "./Blogs/blogs.styles";
import { useDarkMode } from "../DarkModeContext";
import { NavbarLink, NavbarLinkExtended } from "./Header/Header.styles";

function CaseStudy({ CaseStudies, blogsheading, direction }) {
  const sliderRef = useRef(null); // Create a ref for the Slider component
  const [hoveredArrow, setHoveredArrow] = useState(null); // State to track hovered arrow
  const { isDarkMode } = useDarkMode();
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      @media (min-width: 768px) {
        .slick-track {
          padding: 30px;
          height: 650px
        }
      }
      @media (max-width: 767px) {
        .slick-track {
          padding: 0;
          height: 100%;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Function to render custom arrows
  const renderArrows = () => {
    return (
      <div className="slider-arrow">
        <img
          src={hoveredArrow === "left" ? ArrowFilled : Arrow}
          alt="Left Arrow"
          style={{
            width: "54px",
            height: "54px",
            cursor: "pointer",
            marginRight: "10px",
            borderRadius: "50%",
          }}
          onClick={() => sliderRef.current.slickPrev()}
          className="arrow-img"
          onMouseEnter={() => setHoveredArrow("left")}
          onMouseLeave={() => setHoveredArrow(null)}
        />
        <img
          src={hoveredArrow === "right" ? ArrowFilled : Arrow}
          alt="Right Arrow"
          style={{
            width: "54px",
            height: "54px",
            cursor: "pointer",
            transform: "rotate(180deg)",
            borderRadius: "50%",
          }}
          onClick={() => sliderRef.current.slickNext()}
          className="arrow-img"
          onMouseEnter={() => setHoveredArrow("right")}
          onMouseLeave={() => setHoveredArrow(null)}
        />
      </div>
    );
  };

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 1000,
    centerMode: true, // Center mode to center the slide
    centerPadding: "-10px", // No padding on the sides
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    dots: true,
    slidesToShow: 3, // Show 3 slides by default
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: false, // Hide arrows on smaller screens
          centerMode: true, // Enable center mode for smaller screens
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false, // Hide arrows on smaller screens
          centerMode: true, // Enable center mode for smaller screens
          centerPadding: "0px", // No padding on the sides
        },
      },
    ],
  };

  return (
    <BlogsContainer theme={isDarkMode} direction={direction}>
      <BlogsSub theme={isDarkMode}>Case Studies</BlogsSub>
      <BlogsHeading theme={isDarkMode}>{blogsheading}</BlogsHeading>
      <Slider {...settings} style={{ width: "100%", display: "flex" }} ref={sliderRef} theme={isDarkMode}>
        {CaseStudies &&
          Array.isArray(CaseStudies) &&
          CaseStudies.map((blog, index) => (
            <BlogInner key={index} theme={isDarkMode}>
            {console.log("Location State:", blog.headline)}
              <NavLink to={`/CaseStudy/${encodeURIComponent(blog.headline)}`} style={{ textDecoration: "none", cursor: "pointer" }}> {/* Wrap BlogCard with NavLink */}
                <BlogCard style={{ width: "90%", backgroundImage: `url(${blog.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', boxShadow: 'inset 0 -290px 50px -50px rgba(0,0,0,0.8), inset 0 -20px 20px -20px rgba(0,0,0,0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', cursor: "pointer" }} theme={isDarkMode}>
                  <BlogText style={{ width: "90%", color: 'white' }} theme={isDarkMode}>
                    <BlogHeading theme={isDarkMode} style={{color:"white"}}>{blog.headline}</BlogHeading>
                  </BlogText>
                  {/* Dynamically generate the PDF link */}
                  {window.innerWidth >= 968 ? (
                    <NavbarLinkExtended style={{ alignSelf: "baseline", fontWeight: "bolder", fontSize: "15px", color: 'white' }} to={`/CaseStudy/${encodeURIComponent(blog.headline)}`}>Read More</NavbarLinkExtended>
                  ) : (
                    <NavbarLinkExtended style={{ alignSelf: "baseline", fontWeight: "bolder", fontSize: "15px", color: 'white' }} to={`/CaseStudy/${encodeURIComponent(blog.headline)}`}>Read More</NavbarLinkExtended>
                  )}
                </BlogCard>
              </NavLink>
            </BlogInner>
          ))}
      </Slider><br /><br />
      {renderArrows()} {/* Render custom arrows */}
    </BlogsContainer>
  );
}

export default CaseStudy;
